import React, { useEffect, useState } from "react";
import { MdClose, MdCheck, MdPictureAsPdf, MdVerified } from "react-icons/md";
import Passport from "../../assets/images/passport.jpg";
import { Document, Page } from "react-pdf";
import { useLocalStore } from "../../store";
import { getImageUrl } from "../common/imageUtils";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

const PDFViewer = ({ data }) => {
  const [file, setFile] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onFileChange(event) {
    setFile(event.target.files[0]);
  }

  return (
    <div className="h-36 bg-blue-300">
      <Document
        file={data?.files[0]?.path}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div>
  );
};

const DocumentCard = ({ openBtn, doc }) => {
  const { currentRole } = useLocalStore();
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (doc?.files) {
        const url = await getImageUrl(doc?.files);
        setImageUrl(url);
      }
    };

    fetchImageUrl();
  }, [doc?.files]);
  useEffect(() => {
    console.log(doc);
  }, []);

  return (
    <div className="relative bg-white rounded-lg overflow-hidden shadow-lg">
      {doc?.document_type?.file_type === "pdf" ? (
        <div
          onClick={currentRole !== "EMR" ? openBtn : () => {}}
          className="h-52 w-full flex items-center justify-center bg-blue-300"
        >
          <PDFViewer
            file={{
              url: doc?.files[0]?.path,
              name: doc?.files[0]?.name,
            }}
            className="w-full h-full"
          >
            <MdPictureAsPdf className="w-24 h-24 text-white" />
          </PDFViewer>
        </div>
      ) : (
        <div
          onClick={openBtn}
          className="h-52 w-full flex items-center justify-center bg-blue-300"
        >
          <img
            src={
              !doc?.files[0]
                ? doc?.files[0]?.path
                : "https://png.pngtree.com/png-clipart/20220612/original/pngtree-pdf-file-icon-png-png-image_7965915.png"
            }
            alt={doc?.title}
            className="h-full"
          />
        </div>
      )}
      <div className="px-6 py-4">
        <h3 className="text-xl font-bold mb-2">
          {doc?.title ? doc?.title : doc?.files[0]?.name}
        </h3>
        <a
          target="_blank"
          href={imageUrl}
        >
          <p className=" text-left text-sm text-N40 mb-8">Lnk</p>
        </a>
        <p className="text-gray-700 text-sm mb-4">
          {doc?.properties ? doc?.properties?.name : doc?.files[0]?.name}
        </p>
        {currentRole !== "wp:emr:generator" ? (
          <>
            {doc?.review_status === false ? (
              <div className="flex items-center justify-between bg-red10/20 rounded-lg px-3 py-1">
                <span className="text-red-700 text-sm font-medium">
                  Rejected
                </span>
                <MdClose className="text-red-700 w-5 h-5" />
              </div>
            ) : doc?.review_status === true ? (
              <div className="flex items-center justify-between bg-green80/25 rounded-lg px-3 py-1">
                <span className="text-green-700 text-sm font-medium">
                  Verified
                </span>
                <MdCheck className="text-green-700 w-5 h-5" />
              </div>
            ) : (
              <div className="flex items-center justify-between bg-yellow80/25 rounded-lg px-3 py-1">
                <span className="text-yellow40 text-sm font-medium">
                  Pending
                </span>
                <MdCheck className="text-green-700 w-5 h-5" />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="rounded-lg py-2 px-2 bg-gray-100 flex gap-2 items-center justify-start">
              {doc?.review_status ? (
                <>
                  <div className="rounded-full bg-gray-500 w-12 h-12"></div>
                  <div className="flex flex-col">
                    <span></span>
                    {/* <small> */}
                    <div className="flex items-center justify-start rounded-lg py-0">
                      <span className="text-green70 text-xs font-normal">
                        Verified
                      </span>
                      <MdVerified className="text-green70 w-3 h-3" />
                    </div>
                    {/* </small> */}
                  </div>
                </>
              ) : (
                "Not Checked Yet"
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentCard;
