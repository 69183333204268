import { gql } from "@apollo/client";

export const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken {
      tokens {
        access_token
        refresh_token
      }
      data {
        email
        id
        phoneNumber
        role
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgot_password($phone: String = "") {
    forgotPassword(params: { phone: $phone }) {
      error
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation reset_password(
    $otp: String = ""
    $phone: String = ""
    $pass: String = ""
  ) {
    resetPassword(params: { otp: $otp, phone: $phone, newPassword: $pass }) {
      message
      error
      token
    }
  }
`;

export const WORK_PERMIT_LOGIN = gql`
  mutation login($username: String = "", $password: String = "") {
    signin(params: { email: $username, password: $password }) {
      token
      error
    }
  }
`;

export const FINANCIAL_DOC_VERIFY = gql`
  mutation myQuery($verify: Boolean) {
    signin(username: $username, password: $password) {
      token {
        access_token
        refresh_token
      }
      email_verified
      roles
      user_id
    }
  }
`;

export const VERIFY_FINANCE_DOC_SLIP = gql`
  mutation updateVerify($id: uuid = "", $verified: Boolean = false) {
    update_work_permit_finance_documents_by_pk(
      pk_columns: { id: $id }
      _set: { verified: $verified }
    ) {
      verified
      files
      id
      reference_number
      created_at
    }
  }
`;

export const REJECT_FINANCE_DOC_SLIP = gql`
  mutation rejectFinanceDoc(
    $id: uuid = ""
    $verified: Boolean = false
    $verified_by_id: uuid = ""
    $verified_at: timestamptz = ""
  ) {
    update_work_permit_finance_documents_by_pk(
      pk_columns: { id: $id }
      _set: {
        verified: $verified
        verified_at: $verified_at
        verified_by_id: $verified_by_id
      }
    ) {
      verified
      files
      id
      reference_number
      created_at
    }
  }
`;

export const UPDATE_WORK_PERMIT_DOCUMENT = gql`
  mutation update_work_permit(
    $id: uuid = ""
    $verified: Boolean = false
    $verified_by: uuid = ""
    $verified_on: timestamptz = ""
  ) {
    update_work_permit_request_documents_by_pk(
      pk_columns: { id: $id }
      _set: {
        verified: $verified
        verified_on: $verified_on
        verified_by: $verified_by
      }
    ) {
      document_type {
        name
      }
      verified
      files
      id
      created_at
    }
  }
`;

export const REJECT_WORK_PERMIT_DOCUMENT = gql`
  mutation reject_work_permit(
    $id: uuid = ""
    $verified: Boolean = false
    $remark: String = ""
    $verified_by: uuid = ""
    $verified_on: timestamptz = ""
  ) {
    update_work_permit_request_documents_by_pk(
      pk_columns: { id: $id }
      _set: {
        verified: $verified
        verification_remark: $remark
        verified_on: $verified_on
        verified_by: $verified_by
      }
    ) {
      document_type {
        name
      }
      verified
      files
      id
      created_at
    }
  }
`;
// $_set: work_permit_request_documents_set_input

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($code: String!) {
    verifyEmail(code: $code) {
      token {
        access_token
        refresh_token
      }
    }
  }
`;

export const EMAIL_RESEND = gql`
  mutation ResendEmail {
    resendVerificationEmail {
      sent
    }
  }
`;

export const SIGN_UP = gql`
  mutation Signup(
    $email: String = ""
    $name: String = ""
    $password: String = ""
    $phone_number: String = "" # $roles: String = ""
  ) {
    signup(
      params: {
        email: $email
        name: $name
        password: $password
        phone_number: $phone_number
      }
    ) {
      message
      error
    }
  }
`;

export const RESEND_VERIFY_CODE = gql`
  mutation resendVerificationCode($phone_number: String = "") {
    resendVerificationCode(params: { phone_number: $phone_number }) {
      message
      error
    }
  }
`;

export const VERIFY_CODE = gql`
  mutation verify_code($otp: String = "", $phone_number: String = "") {
    verifyUser(params: { otp: $otp, phone_number: $phone_number }) {
      is_verified
      token {
        access_token
        refresh_token
      }
      user_id
    }
  }
`;

export const SIGN_IN = gql`
  mutation SIGN_IN($username: String = "", $password: String = "") {
    signin(password: $password, username: $username) {
      token {
        access_token
        refresh_token
      }
      email_verified
      roles
      user {
        email
        id
        name
        phone_number
      }
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganiztaion($object: orgnizations_insert_input!) {
    insert_orgnizations_one(object: $object) {
      credential {
        email
        name
        id
      }
      id
    }
  }
`;

export const CREATE_WORK_PERMIT = gql`
  mutation CreateWorkPermit($object: work_permit_requests_insert_input!) {
    insert_work_permit_requests_one(object: $object) {
      id
      first_name
      father_name
    }
  }
`;

export const CREATE_EDUCATION_BACKGROUND = gql`
  mutation CreateEducationBackground(
    $object: education_backgrounds_insert_input!
  ) {
    insert_education_backgrounds_one(object: $object) {
      id
    }
  }
`;

export const CREATE_WORK_EXPERIENCES = gql`
  mutation CreateWorkExperiences($objects: [work_experiences_insert_input!]!) {
    insert_work_experiences(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_WORK_PLACE = gql`
  mutation CreateWorkPlace($object: workplaces_insert_input = {}) {
    insert_workplaces_one(object: $object) {
      id
    }
  }
`;

export const ADD_SKILLS = gql`
  mutation MyMutation($skills: String, $id: uuid = "") {
    update_work_permit_requests_by_pk(
      pk_columns: { id: $id }
      _set: { skills: $skills }
    ) {
      id
      skills
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation UploadFile(
    $extension: String!
    $file: String!
    $FolderId: String! = "DEFAULT"
  ) {
    uploadFile(extension: $extension, file: $file, FolderId: $FolderId) {
      msg
      info {
        Bucket
        Key
      }
    }
  }
`;

export const INSERT_DOCUMENTS = gql`
  mutation InsertDocuments(
    $files: jsonb
    $document_type_id: uuid
    $title: String
    $work_permit_request_id: uuid
  ) {
    insert_work_permit_request_documents(
      objects: {
        work_permit_request_id: $work_permit_request_id
        document_type_id: $document_type_id
        files: $files
        title: $title
      }
    ) {
      returning {
        id
        files
      }
    }
  }
`;

// =================================================
export const CREATE_ORGANIZATION_DATA = gql`
  mutation create_organization(
    $business_license_number: String = ""
    $district_id: uuid = null
    $email: String = ""
    $woreda_id: uuid = null
    $tin_number: String = ""
    $subcity_id: uuid! = null
    $sector_id: uuid = ""
    $region_id: uuid = ""
    $pobox: String = ""
    $fax: String = ""
    $local_employees_count: Int = 0
    $expatriates_count: Int = 0
    $name: String = ""
    $phone_no: jsonb
    $organization_type_id: uuid = ""
    # $organization_documents: jsonb
    $house_number: String = ""
    $zone_id: uuid = null # $created_by: uuid = "" # $user_id: uuid = ""
    $business_license_issue_date: date
    $business_license_expiry_date: date
  ) {
    insert_organizations_one(
      object: {
        business_licence_number: $business_license_number
        business_licence_issued_date: $business_license_issue_date
        business_licence_expiry_date: $business_license_expiry_date
        district_id: $district_id
        email: $email
        woreda_id: $woreda_id
        tin_number: $tin_number
        subcity_id: $subcity_id
        sector_id: $sector_id
        region_id: $region_id
        zone_id: $zone_id
        pobox: $pobox
        fax: $fax
        local_employees_count: $local_employees_count
        expatriates_count: $expatriates_count
        name: $name
        phone_no: $phone_no
        organization_type_id: $organization_type_id
        # organization_documents: { data: { files: $organization_documents } }
        house_number: $house_number
        # created_by: $created_by
        # user_id: $user_id
      }
    ) {
      id
      name
    }
  }
`;

export const ORGANIZATION_RENEWAL = gql`
  mutation ORGANIZATION_RENEWAL(
    $business_license_expiry_date: date
    $business_license_issued_date: date
    $orgID: uuid
  ) {
    insert_organization_renewal(
      objects: {
        expiry_date: $business_license_expiry_date
        issued_at: $business_license_issued_date
        organization_id: $orgID
      }
    ) {
      affected_rows
      returning {
        organization_id
      }
    }
  }
`;

export const CREATE_ORGANIZATION_DATA_UPDATE = gql`
  mutation create_organization(
    $id: uuid
    $business_licence_number: String = ""
    $district_id: uuid = null
    $email: String = ""
    $woreda_id: uuid = null
    $tin_number: String = ""
    $subcity_id: uuid! = null
    $sector_id: uuid = ""
    $region_id: uuid = ""
    $pobox: String = ""
    $fax: String = ""
    $local_employees_count: Int = 0
    $expatriates_count: Int = 0
    $name: String = ""
    $phone_no: jsonb
    $organization_type_id: uuid = ""
    # $organization_documents: jsonb
    $house_number: String = ""
    $zone_id: uuid = null # $created_by: uuid = "" # $user_id: uuid = ""
  ) {
    update_organizations(
      where: { id: { _eq: $id } }
      _set: {
        # created_by: "e8ce9c46-b4bc-4fbe-a54b-2398bd9613f9"
        business_licence_number: $business_licence_number
        district_id: $district_id
        email: $email
        woreda_id: $woreda_id
        tin_number: $tin_number
        subcity_id: $subcity_id
        sector_id: $sector_id
        region_id: $region_id
        zone_id: $zone_id
        pobox: $pobox
        fax: $fax
        local_employees_count: $local_employees_count
        expatriates_count: $expatriates_count
        name: $name
        phone_no: $phone_no
        organization_type_id: $organization_type_id
        house_number: $house_number
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

// verify org
export const VERIFY_ORG = gql`
  mutation verifyOrg(
    $id: uuid = ""
    $status: Boolean = false
    $note: String = ""
  ) {
    update_organizations(
      where: { id: { _eq: $id } }
      _set: { review_status: $status, review_note: $note }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export const VERIFY_RENEWAL_ORG = gql`
  mutation verifyOrg(
    $id: uuid = ""
    $status: Boolean = false
    $note: String = ""
  ) {
    update_organization_renewal(
      where: { organization_id: { _eq: $id } }
      _set: { review_status: $status, review_note: $note }
    ) {
      affected_rows
      returning {
        organization_id
        review_status
      }
    }
  }
`;

export const INSERT_EXPATRIATE_REQUEST = gql`
  mutation insert_expatriate_request(
    $date_of_birth: date = ""
    $skills: jsonb
    $profession_title_id: uuid = ""
    $first_name: String = ""
    $father_name: String = ""
    $grand_father_name: String = ""
    $allowance: numeric = 0
    $employment_date: date = ""
    # $employment_start_date: date = ""
    $employment_end_date: date = ""
    $salary: numeric = 0
    $organization_id: uuid = ""
    $occupation_category_id: uuid = ""
    $issued_date: date = ""
    $expiry_date: date = ""
    $passport_issued_date: date = ""
    $passport_expiry_date: date = ""
    $visa_issued_date: date = null
    $visa_expiry_date: date = null
    $intended_position: String = ""
    $gender: uuid = ""
    $nationality_id: uuid = ""
    $marital_status: uuid = ""
    $passport_number: String = ""
    $visa_number: String = ""
    $visa_type: uuid = ""
    $way_of_entry: uuid = ""
    $created_by: uuid = ""
    $permit_type: uuid = ""
    $reference_number: String = ""
    $emr_number: String = null
  ) {
    insert_expatriates(
      objects: {
        date_of_birth: $date_of_birth
        skills: $skills
        profession_title_id: $profession_title_id
        first_name: $first_name
        father_name: $father_name
        grand_father_name: $grand_father_name
        gender_id: $gender
        nationality_id: $nationality_id
        marital_status_id: $marital_status
        passport_number: $passport_number
        visa_number: $visa_number
        visa_type_id: $visa_type
        way_of_entry_id: $way_of_entry
        expatriate_work_permits: {
          data: {
            allowance: $allowance
            salary: $salary
            occupation_category_id: $occupation_category_id
            start_date: $employment_date
            end_date: $employment_end_date
            permit_type: $permit_type
            reference_number: $reference_number
            organization_id: $organization_id
            # issued_date: $issued_date
            # expiry_date: $expiry_date
            emr_number: $emr_number
            passport_issued_date: $passport_issued_date
            passport_expiry_date: $passport_expiry_date
            visa_issued_date: $visa_issued_date
            visa_expiry_date: $visa_expiry_date
            intended_position: $intended_position
          }
        }
      }
    ) {
      affected_rows
      returning {
        id
        first_name
        expatriate_work_permits {
          id
        }
      }
    }
  }
`;

export const INSERT_WORK_COUNTER_PART = gql`
  mutation insert_counter_part(
    $assigned_date: date
    $first_name: String = ""
    $last_name: String = ""
    $work_permit_id: uuid = ""
    $position: String = ""
  ) {
    insert_expatriate_counterparts_one(
      object: {
        assigned_date: $assigned_date
        first_name: $first_name
        last_name: $last_name
        position: $position
        expatriate_work_permit_id: $work_permit_id
      }
    ) {
      id
      first_name
    }
  }
`;

// Assign work permit
export const ASSIGN_WORK_PERMIT = gql`
  mutation assign_work_permit(
    $id: String = ""
    $roles: [String] = ["wp:finance", "wp:doc:checker"]
  ) {
    assignWorkPermitReviewer(params: { work_permit_id: $id, roles: $roles }) {
      error
      work_permit_id
      employees {
        employee {
          name
          id
        }
      }
    }
  }
`;

export const INSERT_ORGANIZATION_DOC = gql`
  mutation insert_org_doc(
    $orgID: uuid
    $files: jsonb
    $document_type: uuid = ""
  ) {
    insert_organization_documents_one(
      object: {
        organization_id: $orgID
        files: $files
        document_type_id: $document_type
      }
    ) {
      id
      review_status
    }
  }
`;

export const UPDATE_ORGANIZATION_DOC = gql`
  mutation insert_org_doc(
    $docID: uuid
    $orgID: uuid
    $files: jsonb
    $document_type: uuid = ""
  ) {
    update_organization_documents(
      where: { id: { _eq: $docID } }
      _set: {
        organization_id: $orgID
        files: $files
        document_type_id: $document_type
      }
    ) {
      affected_rows
      returning {
        id
        review_status
      }
    }
  }
`;

export const INSERT_EXPATERIATE_WORK_PLACE = gql`
  mutation InsertExpatriateWorkPlaces(
    $phone_number: jsonb
    $email: String
    $house_number: String
    $kebele: String
    $project_name: String
    # $created_by: uuid = ""
    $district_id: uuid
    $expatriate_work_permit_id: uuid
    $region_id: uuid
    $subcity_id: uuid
    # $updated_by: uuid = ""
    $woreda_id: uuid
    $zone_id: uuid
  ) {
    insert_expatriate_work_places_one(
      object: {
        phone_number: $phone_number
        email: $email
        house_number: $house_number
        kebele: $kebele
        project_name: $project_name
        district_id: $district_id
        expatriate_work_permit_id: $expatriate_work_permit_id
        region_id: $region_id
        subcity_id: $subcity_id
        # created_by: $created_by
        # updated_by: $updated_by
        woreda_id: $woreda_id
        zone_id: $zone_id
      }
    ) {
      id
    }
  }
`;

// mutation exp {
//   insert_expatriate_work_permits_one(object:{
//     activity_notes:"",
//     allowance:"",
//     end_date:"",
//     end_date:"",
//     expatriate_id:"",
//     user:"",
//     salary:"",
//     permit_type:"",
//     organization_id:"",
//     occupation_category_id:""
//   })
// }

export const INSERT_RENEWAL_PERMIT_REQUEST = gql`
  mutation insert_renewal_permit_request(
    $expatriate_id: uuid
    $allowance: numeric = 0
    $permit_type: uuid
    $reference_number: String = ""
    $employment_start_date: date = ""
    $employment_end_date: date = ""
    $salary: numeric = 0
    $organization_id: uuid
    $occupation_category_id: uuid
    $issued_date: date = ""
    $expiry_date: date = ""
    $emr_number: String = ""
  ) {
    insert_expatriate_work_permits(
      objects: {
        expatriate_id: $expatriate_id
        allowance: $allowance
        permit_type: $permit_type
        reference_number: $reference_number
        start_date: $employment_start_date
        end_date: $employment_end_date
        salary: $salary
        organization_id: $organization_id
        occupation_category_id: $occupation_category_id
        issued_date: $issued_date
        expiry_date: $expiry_date
        emr_number: $emr_number
      }
    ) {
      affected_rows
      returning {
        id
        review_status
      }
    }
  }
`;
export const INSERT_EXPATRIATE_NEW_DOC = gql`
  mutation insert_expatriate_document(
    $documentId: uuid = ""
    $expatriate_work_permit_id: uuid = ""
    $files: jsonb
    $properties: jsonb
    $title: String = ""
    $delegation_file_number: String = ""
  ) {
    insert_expatriate_work_permit_documents(
      objects: {
        document_type_id: $documentId
        expatriate_work_permit_id: $expatriate_work_permit_id
        files: $files
        properties: $properties
        title: $title
        delegation_file_number:$delegation_file_number
      }
    ) {
      affected_rows
      returning {
        id
        review_status
      }
    }
  }
`;
export const INSERT_EXPATRIATE_DOC = gql`
  mutation insert_expatriate_document(
    $documentId: uuid = ""
    $expatriate_work_permit_id: uuid = ""
    $files: jsonb
    $properties: jsonb
    $title: String = ""
  ) {
    insert_expatriate_work_permit_documents(
      objects: {
        document_type_id: $documentId
        expatriate_work_permit_id: $expatriate_work_permit_id
        files: $files
        properties: $properties
        title: $title
      }
    ) {
      affected_rows
      returning {
        id
        review_status
      }
    }
  }
`;

export const INSERT_EDUCATION_BACKGROUND = gql`
  mutation insert_expatriate_education(
    $attachment: jsonb = ""
    $field_of_study: String = ""
    $institution_name: String = ""
    $expatriate_id: uuid = ""
    $qualification_level_id: uuid = ""
    $qualification_type_id: uuid = ""
    $received_date: date = ""
  ) {
    insert_expatriate_education_backgrounds_one(
      object: {
        attachments: $attachment
        expatriate_id: $expatriate_id
        field_of_study: $field_of_study
        institution_name: $institution_name
        qualification_level_id: $qualification_level_id
        qualification_type_id: $qualification_type_id
        received_date: $received_date
      }
    ) {
      __typename
    }
  }
`;

export const INSERT_WORK_EXPERIENCE = gql`
  mutation insert_work_experiance(
    $attachment: jsonb = ""
    $currently_working: Boolean = false
    $end_date: date = ""
    $start_date: date = ""
    $expatriate_id: uuid = ""
    $years_of_work_experience: Int = 0
    $institution_name: String = ""
    $position: String = ""
    $service_type_id: uuid = ""
    $name_of_service: String = ""
  ) {
    insert_expatriate_work_experiences_one(
      object: {
        attachments: $attachment
        currently_working: $currently_working
        end_date: $end_date
        expatriate_id: $expatriate_id
        institution_name: $institution_name
        years_of_work_experience: $years_of_work_experience
        start_date: $start_date
        service_type_id: $service_type_id
        position: $position
        name_of_service: $name_of_service
      }
    ) {
      # id
      # review_status
      __typename
    }
  }
`;

export const VERIFY_EXPATRIATE_DOCUMENT = gql`
  mutation verifyDoc(
    $id: uuid
    $review_note: String = ""
    $review_status: Boolean = false
  ) {
    update_expatriate_work_permit_documents(
      where: { id: { _eq: $id } }
      _set: { review_note: $review_note, review_status: $review_status }
    ) {
      affected_rows
      returning {
        review_status
      }
    }
  }
`;

// ===========================================SYS========================
// mutation employee
export const REGISTER_EMPLOYEE_USER = gql`
  mutation registerEmployee(
    $email: String = ""
    $name: String = ""
    $role: [String!]!
    $phone: String = ""
    $password: String = ""
  ) {
    registerEmployee(
      params: {
        email: $email
        name: $name
        password: $password
        phone_number: $phone
        roles: $role
      }
    ) {
      error
      message
      token
    }
  }
`;

export const UPDATE_EMPLOYEE_STATUS = gql`
  mutation update_employee_status($userID: uuid, $status: Boolean = false) {
    update_employees(
      where: { user_id: { _eq: $userID } }
      _set: { active_status: $status }
    ) {
      affected_rows
      returning {
        active_status
        user_id
      }
    }
  }
`;

export const INSERT_EMPLOYEE = gql`
  mutation insert_employees($user_id: uuid = "") {
    insert_employees(objects: { user_id: $user_id }) {
      affected_rows
      returning {
        user_id
        active_status
      }
    }
  }
`;

/**
 * @description for organizatio to update bank reference number
 */
export const UPDATE_WORK_PERMIT_BANK = gql`
  mutation updatePermitRefernce($id: uuid, $reference: String = "") {
    update_expatriate_work_permits(
      where: { id: { _eq: $id } }
      _set: { reference_number: $reference }
    ) {
      affected_rows
    }
  }
`;

export const ADD_USER_ROLE = gql`
  mutation add_user_role($userID: uuid = "", $roleID: uuid = "") {
    insert_user_roles_one(object: { user_id: $userID, role_id: $roleID }) {
      user {
        id
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $oldPassword: String = ""
    $newPassword: String = ""
  ) {
    changePassword(
      params: { oldPassword: $oldPassword, newPassword: $newPassword }
    ) {
      message
      error
    }
  }
`;

export const ORGANIZATION_DOCUMENT_VERIFY = gql`
  mutation org_doc_verify(
    $id: uuid = ""
    $note: String = ""
    $status: Boolean = false
  ) {
    update_organization_documents(
      where: { id: { _eq: $id } }
      _set: { review_note: $note, review_status: $status }
    ) {
      affected_rows
      returning {
        review_status
      }
    }
  }
`;

export const VERIFY_PERMIT = gql`
  mutation verify_permit($id: uuid = "") {
    update_expatriate_work_permits(
      where: { id: { _eq: $id } }
      _set: { review_status: true }
    ) {
      affected_rows
    }
  }
`;

/**
 * @description Verifiy Pwemit Document status when they finalized reviewing
 * @access Document Checker
 */
export const VERIFY_FINISH_PERMIT = gql`
  mutation verify_finish_permit(
    $permitID: uuid = ""
    $checkerStatus: Boolean = null
    $status: Boolean = null
    $note: String
  ) {
    update_expatriate_work_permits(
      where: { id: { _eq: $permitID } }
      _set: {
        document_checker_status: $checkerStatus
        review_status: $status
        reviewed_note: $note
      }
    ) {
      affected_rows
      returning {
        document_checker_status
        document_status
      }
    }
  }
`;

export const GENERATE_IMMEGRATION_PAPER = gql`
  mutation generatePAPER(
    $emr: String!
    $arrivalDate: String!
    $job: String!
    $expiryDate: String!
    $requestedDate: String!
  ) {
    generateDocument(
      params: {
        emr_number: $emr
        arrivalDate: $arrivalDate
        job: $job
        expiryDate: $expiryDate
        requestedDate: $requestedDate
      }
    ) {
      
      url
    }
  }
`;

export const GENERATE_CANCELATION_PAPER = gql`
  mutation generateCancelDocument(
    $emr: String!
    $job: String!
    $expiryDate: String!
    $requestedDate: String!
  ) {
    generateCancelDocument(
      params: {
        emr_number: $emr
        job: $job
        expiryDate: $expiryDate
        requestedDate: $requestedDate
      }
    ) {
      url
    }
  }
`;

export const INSERT_ASSIGN_EMPLOYEE = gql`
  mutation insert_assign_employee($empID: uuid = "", $permitID: uuid = "") {
    insert_assigned_workpermit_reviewers(
      objects: { employee_id: $empID, expatriate_work_permit_id: $permitID }
    ) {
      affected_rows
    }
  }
`;

// =================================EMR======================
// export const VERIFY_PERMIT
// export const REJECT_WORK_PERMIT = gql`
//   mutation rejectpermit($permitID: uuid, ){
//     update_expatriate_work_permits(
//       where: { id: { _eq: "" } }
//       _set: {
//         review_status: false
//         document_checker_status: false
//         reviewed_note: ""
//       }
//     ) {
//       affected_rows
//       returning {
//         reviewed_by
//         reviewed_note
//         review_status
//       }
//     }
//   }
// `;

export const UPDATE_RENEWAL_PERMIT = gql`
  mutation renewalUpdatePermit(
    $emr: String
    $issue_date: date
    $expired_date: date
  ) {
    update_expatriate_work_permits(
      where: { emr_number: { _eq: $emr } }
      _set: {
        review_status: true
        work_permit_issued_date: $issue_date
        expiry_date: $expired_date
      }
    ) {
      affected_rows
    }
  }
`;

export const SYS_ADMIN_EXPAT_UPDATE = gql`
  mutation sysUpdateExpat(
    $id: uuid
    $first_name: String
    $father_name: String
    $grand_father_name: String
    $passport_number: String
    $date_of_birth: date
  ) {
    update_expatriates(
      where: { id: { _eq: $id } }
      _set: {
        first_name: $first_name
        father_name: $father_name
        grand_father_name: $grand_father_name
        passport_number: $passport_number
        date_of_birth: $date_of_birth
      }
    ) {
      returning {
        id
        passport_number
      }
    }
  }
`;

// Organization Expat Profile Update
export const UPDATE_EXPAT_PROFILE = gql`
  mutation UPDATE_EXPAT_PROFILE(
    $id: uuid
    $fname: String
    $mname: String
    $lname: String = null
    $passport: String
    $date_of_birth: date
    $nationality_id: uuid
  ) {
    update_expatriates(
      where: { id: { _eq: $id } }
      _set: {
        first_name: $fname
        father_name: $mname
        grand_father_name: $lname
        passport_number: $passport
        nationality_id: $nationality_id
        date_of_birth: $date_of_birth
      }
    ) {
      returning {
        id
        passport_number
      }
    }
  }
`;

export const UPDATE_PERMIT_PROFILE = gql`
  mutation UPDATE_PERMIT_PROFILE(
    # $passport_issue_date: date
    # $passport_expiry_date: date
    # $visa_issue_date: date
    # $visa_expiry_date: date
    $start_date: date
    $end_date: date
    $emr_number: String = null
    $id: uuid
  ) {
    update_expatriate_work_permits(
      where: { id: { _eq: $id } }
      _set: {
        # passport_expiry_date: $passport_expiry_date
        # passport_issued_date: $passport_issue_date
        # visa_expiry_date: $visa_expiry_date
        # visa_issued_date: $visa_issue_date
        start_date: $start_date
        end_date: $end_date
        emr_number: $emr_number
      }
    ) {
      returning {
        id
        emr_number
      }
    }
  }
`;

// SWap expatriate to another organization and register permit
export const SWAP_EXPATRIATE_PERMIT = gql`
  mutation SWAP_EXPATRIATE_PERMIT(
    $allowance: numeric
    $arrival_date: date
    $visa_expiry_date: date
    $visa_issued_date: date
    $passport_expiry_date: date
    $passport_issued_date: date
    $start_date: date
    $end_date: date
    $salary: numeric
    $reference_number: String
    $permit_type: uuid
    $organization_id: uuid
    $expatriate_id: uuid
    $occupation_category_id: uuid
    $intended_position: String
  ) {
    insert_expatriate_work_permits(
      objects: {
        allowance: $allowance
        arrival_date: $arrival_date
        visa_issued_date: $visa_issued_date
        visa_expiry_date: $visa_expiry_date
        passport_expiry_date: $passport_issued_date
        passport_issued_date: $passport_expiry_date
        start_date: $start_date
        end_date: $end_date
        # service_type_id: $service_type_id
        salary: $salary
        reference_number: $reference_number
        permit_type: $permit_type
        organization_id: $organization_id
        occupation_category_id: $occupation_category_id
        intended_position: $intended_position
        expatriate_id: $expatriate_id
        issued_date: $passport_issued_date
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_REPLACEMENT = gql`
  mutation UPDATE_REPLACEMENT(
    $permitID: uuid
    $expiryDate: date
    $issueData: date
    $job: String
  ) {
    update_expatriate_work_permits(
      where: { id: { _eq: $permitID } }
      _set: {
        review_status: true
        expiry_date: $expiryDate
        issued_date: $issueData
        intended_position: $job
      }
    ) {
      affected_rows
      returning {
        id
        review_status
      }
    }
  }
`;
