export function getDelegationLetterStatus(data) {
  console.log(data)
  const delegationLetter = data?.expatriate_work_permit_documents.find(
    (doc) => doc.title === "Letter of Delegation"
  );

  if (!delegationLetter) {
    return {
      exists: false,
      isApproved: false,
      message: "Letter of Delegation not found",
    };
  }

  return {
    exists: true,
    isApproved: true,
    message:
      delegationLetter.review_status === true
        ? "Letter of Delegation is approved"
        : "Letter of Delegation exists but is not approved",
    reviewedBy: delegationLetter.reviewed_by_info?.name || null,
  };
}
