import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  APPROVED_EXPATIATES_PERMITS_RENEWAL,
  APPROVED_EXPATIATES_PERMITS_RENEWAL_FILTER,
  COUNT_NOT_ASSIGNED_PERMITS,
  COUNT_TOTAL_PERMIT_ASSGINED,
  DOCUMENT_ALL_APPROVED_WORK_PERMITS,
  EXPAT_BY_ORGANZATION,
  GET_ALL_ASSIGNED_WORK_PERMITS,
  GET_ALL_ORGANIZATION,
  GET_APPLICANT_REQUEST,
  GET_ASSIGNED_ORGANIZATION_LIST,
  GET_EXPATRIATE_LIST,
  GET_FILTERED_PERMIT,
  GET_FILTERED_RENEWAL_PERMIT,
  GET_NOT_ASSIGNED_FILTERED_PERMIT,
  GET_SEARCH_ASSIGNED_WORK_PERMITS,
  SEARCH_EXPATIATES_PERMITS_FILTER_DOCUMENT_CHECKER_FINANCE,
  SEARCH_EXPATIATES_PERMITS_RENEWAL_FILTER_DOCUMENT_CHECKER,
  SEARCH_ORGANIZATION_BG_TIN,
  SEARCH_WORK_PERMITS_FILTER,
  TOTAL_ASSIGNED_WORK_PERMIT,
  TOTAL_ASSIGNED_WORK_PERMIT_ALL,
  TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
  TOTAL_EXPAT_COUNT,
  TOTAL_PERMIT_EXPAT_COUNT,
} from "../../graph-query/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStore } from "../../store";
import Table from "../../components/Table/CTable";
import FinanceDocument from "../../components/utilities/FinanceDocument";
import { toast } from "react-toastify";
import authorize from "../../hooks/withAuthorization";
import {
  ASSIGN_WORK_PERMIT,
  UPDATE_RENEWAL_PERMIT,
  VERIFY_EXPATRIATE_DOCUMENT,
} from "../../graph-query/mutation";
import { BANK_SLIP_ID, BANK_SLIP_ID1, BANK_SLIP_ID2, BANK_SLIP_ID3, WORK_PERMIT_TYPES } from "../../utils/constants";
import { roles } from "../../utils/roles";
import { ApplicantColumns } from "../../datasets/applicants/applicantColumns";
import { useApplicantStore } from "../../store/applicantStore";
import { VerifiedApplicants } from "../../datasets/verified/verifiedColumns";
import { useForm } from "react-hook-form";
import { CInputField, FormProvider } from "../../components/Forms/Fields";
import CButton from "../../components/Forms/CButton";
import { AiFillFilter } from "react-icons/ai";
import FilterBox from "../../components/Forms/FilterBox";
import { useRenewalStore } from "../../store/renewalStore";
import { Can } from "../../permission/Can";

const Renewal = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [queryName, setQueryName] = useState();

  const [organizationData, setOrganizationData] = useState([]);
  const [assignedOrgList, setAssignedOrgList] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [totalExpatList, setTotalExpatList] = useState(0);
  const [loadingRows, setLoadingRows] = useState([]); //for assign loading button
  const [show, setShow] = useState(false);
  const [currentTab, setCurrentTab] = useState("not_assigned");
  const [selectedTab, setSelectedTab] = useState("pending");
  const [selectedValue, setSelectedValue] = useState(null);
  const [allAssignedPermit, setAllAssignedPermit] = useState([]);
  const [renewalTotal, setRenewalTotal] = useState(0);
  const [notAssignedTotalPermit, setNotAssignedTotalPermit] = useState(0);

  const [
    notAssignedRenewalWorkPermitDesk,
    setNotAssignedRenewalWorkPermitDesk,
  ] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const [assign, {}] = useMutation(ASSIGN_WORK_PERMIT, {
    onCompleted: (res) => toast.success("Work Permit Assigned successfully"),
    onError: (er) => toast.error("Error assigning work permit"),
  });
  //==========================================================
  const {
    offset,
    limit,
    setLimit,
    setOffset,
    setWorkPermits,
    // totalAssignedPermit,
    // setTotalAssignedPermit,
    filterOptionTable,
    workPermits,
    assginedWorkPermits,
    currentRole,
  } = useLocalStore();

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const {
    register: registerDesk,
    reset: resetDesk,
    formState: { errors: errorsDesk },
    handleSubmit: handleSubmitDesk,
  } = useForm();

  // =======___+++++++=============______======
  const {
    pending_limit,
    rejected_limit,
    approved_limit,
    pending_offset,
    rejected_offset,
    approved_offset,
    approvedRenewalList,
    rejectedRenewalList,
    pendingRenewalList,
    approvalCount,
    rejectedCount,
    pendingCount,
    setPendingLimit,
    setRejectedLimit,
    setApprovedLimit,
    setPendingOffset,
    setRejectedOffset,
    setApprovedOffset,
    setPendingList,
    setRejectedList,
    setApprovedList,
    setCountApproval,
    setCountPending,
    setCountRejected,
  } = useRenewalStore();

  // ===========================DESK HANDLER=================================================
  /**
   * @description GET all assigned renewal work permit
   * @access desk:handler
   */
  const [getDataFromServer, { loading, error }] = useLazyQuery(
    GET_FILTERED_PERMIT,
    {
      variables: {
        permitType: WORK_PERMIT_TYPES.RENEWAL,
        limit: Number(approved_limit),
        offset: approved_offset * Number(approved_limit),
      },
      context: {
        headers: {
          "Content-Type": "application/json",
        },
      },
      onCompleted: (data) => {
        console.log("___", data);
        // setWorkPermitsRenewal(data?.expatriate_work_permits);
        setApprovedList(data?.expatriate_work_permits);
      },
    }
  );

  /**
   * @description get Not assigned renewal permits
   */
  const [getNotAssignedRenewalPermit, {}] = useLazyQuery(
    GET_NOT_ASSIGNED_FILTERED_PERMIT,
    {
      variables: {
        limit: Number(pending_limit),
        offset: pending_offset * Number(pending_limit),
        permitType: WORK_PERMIT_TYPES.RENEWAL,
      },
      onCompleted: (res) => {
        setPendingList(res?.expatriate_work_permits);
      },
    }
  );

  const [countNotAssigned, {}] = useLazyQuery(COUNT_NOT_ASSIGNED_PERMITS, {
    variables: {
      permitType: WORK_PERMIT_TYPES.RENEWAL,
    },
    onCompleted: (d) => {
      setNotAssignedTotalPermit(
        d?.expatriate_work_permits_aggregate?.aggregate?.count
      );
    },
  });

  const [countExpat, {}] = useLazyQuery(TOTAL_PERMIT_EXPAT_COUNT, {
    variables: {
      permitType: WORK_PERMIT_TYPES.RENEWAL,
    },
    onCompleted: (d) => {
      console.log(
        "count renewal permit",
        d.expatriates_aggregate?.aggregate?.count
      );
      setTotalExpatList(d?.expatriate_work_permits_aggregate?.aggregate?.count);
    },
  });

  /**
   * @description search work permit by their EMR NUMBER
   */
  const [searchPermitEMR, {}] = useLazyQuery(
    APPROVED_EXPATIATES_PERMITS_RENEWAL_FILTER,
    {
      onCompleted: (d) => {
        setPendingList(d?.expatriate_work_permits);
      },
      onError: (er) => console.log(er),
    }
  );

  /**
   * @access DOCUMENT CHECKER
   */
  const [searchPermitDocumentChecker, {}] = useLazyQuery(
    SEARCH_EXPATIATES_PERMITS_RENEWAL_FILTER_DOCUMENT_CHECKER,
    {
      onCompleted: (d) => {
        setPendingList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => console.log(er),
    }
  );

  /**
   *
   * @access DESK Handler
   */
  const [searchRenewalPermit, {}] = useLazyQuery(SEARCH_WORK_PERMITS_FILTER, {
    variables: {
      permit: WORK_PERMIT_TYPES.RENEWAL,
    },
    onCompleted: (res) => {
      setPendingList(res?.expatriate_work_permits);
    },
    onError: (er) => console.log(er),
  });

  const [allOrg, {}] = useLazyQuery(GET_ALL_ORGANIZATION, {
    variables: {
      limit: 1000,
      offset: 0,
    },
    onCompleted: (_data) => {
      setOrganizationData(_data?.organizations);
    },
  });

  const [getAssignedOrg, {}] = useLazyQuery(GET_ASSIGNED_ORGANIZATION_LIST, {
    variables: {
      empID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
    },
    onCompleted: (d) => {
      setAssignedOrgList(d.assigned_organization_reviewers);
    },
  });

  /**
   * @description Filter Renewal Permit
   * @param {*} e
   * @access DESK Handler
   */
  const FilterRenewalTableData = async (e) => {
    console.log(e);
    setCurrentTab("not_assigned");
    await searchRenewalPermit({
      variables: {
        searchValue: e?.filterValue,
      },
    });
  };

  //request new data if not found
  useEffect(() => {
    switch (currentRole) {
      case roles.WP_DESK_HANDLER:
        console.log("getting data from server....");
        allOrg();
        getDataFromServer();
        getNotAssignedRenewalPermit();
        countExpat();
        countNotAssigned();
        break;

      case roles.WP_DOC_CHECKER:
        getTotalAssignedPermits();
        getTotalAssignedPermitsApproved();
        getTotalAssignedPermitsRejected();
        getAllAssignedPermits();
        getAllApprovedPermits();
        getAllRejectedPermits();
        // getAssignedOrg();
        break;

      case roles.WP_FINANCE:
        getTotalAssignedPermits();
        getTotalAssignedPermitsApproved();
        getTotalAssignedPermitsRejected();
        getAllAssignedPermits();
        getAllApprovedPermits();
        getAllRejectedPermits();
        allOrg();
        break;

      case roles.WP_EMR_GENERATOR:
        getRenewalVerifiedPermits();
        getTotalAssignedPermitsAll();
        break;

      default:
        break;
    }
  }, [currentRole]);
  // =========================================================================

  const handleBankSlip = (c) => {
    if (!c?.expatriate_work_permit?.expatriate_work_permit_documents) return null;
  
    const bankSlipDocs = c.expatriate_work_permit.expatriate_work_permit_documents.filter(
      (e) =>
        [BANK_SLIP_ID, BANK_SLIP_ID1, BANK_SLIP_ID2, BANK_SLIP_ID3].includes(e?.document_type?.id)
    );
  
    const reviewedDocs = bankSlipDocs.filter((e) => e?.review_status === true);
    const unreviewedDocs = bankSlipDocs.filter((e) => e?.review_status === null);
  
    // If there are both reviewed and unreviewed docs, return the latest unreviewed one
    if (reviewedDocs.length > 0 && unreviewedDocs.length > 0) {
      return unreviewedDocs.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))[0];
    }
  
    // Otherwise, return the latest reviewed or unreviewed document
    return bankSlipDocs.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))[0];
  };
  

  const openModal = (e) => {
    console.log("slip", e);
    setShowModal(true);
    let files = handleBankSlip(e?.row?.original);
    console.log(files)
    setModalData({
      files: files?.files,
      id: files?.id,
      organization:
        e?.row?.original?.expatriate_work_permit?.organization?.name,
      name:
        e.row.original?.expatriate_work_permit?.expatriate?.first_name +
        " " +
        e?.row?.original?.expatriate_work_permit?.expatriate?.father_name,
      reference_number:
        e?.row?.original?.expatriate_work_permit?.reference_number,
    });
  };
console.log(modalData)
  const handleClose = () => {
    setShowModal(false);
    //get applicant data
    getAllAssignedPermits({ fetchPolicy: "network-only" });
  };

  const handlePopUp = (e) => {
    console.log("test pop up clicked!", e);
    if (e) {
      try {
        setShow((prev) => !prev);
        setQueryName(e?.emr);
      } catch (error) {
        console.log("something is wrong, try again!");
      }
    } else {
      toast.error("Emr Number is required!");
    }
  };

  const handleReviewStatus = (c) => {
    let r = handleBankSlip(c);
    console.log(r)
    // console.log(r);
    if (r?.review_status === true) {
      return "Verifed";
    }
    if (r?.review_status === false) {
      return "Rejected";
    }
    if (r?.review_status === null) {
      return "Pending";
    }
  };

  /**
   * @description handle refersh table
   */
  const handleRefershTable = async () => {
    console.log("refersh clicked");
    setOffset(0);
    if (
      currentRole === roles.WP_DOC_CHECKER ||
      currentRole === roles.WP_FINANCE
    ) {
      // setAssignedRenewalPermit([]);
      await getAllAssignedPermits({
        variables: { limit: Number(limit), offset: 0 * Number(limit) },
        fetchPolicy: "network-only",
      });
    }
    if (currentRole === roles.WP_DESK_HANDLER) {
      setWorkPermits([]);
      await getDataFromServer({
        variables: { limit: limit, offset: 0 * limit },
        fetchPolicy: "network-only",
      });
    }
    if (currentRole === roles.WP_EMR_GENERATOR) {
      // setAssignedRenewalPermit([]);
      await getRenewalVerifiedPermits({
        variables: {
          limit: Number(pending_limit),
          offset: 0 * Number(pending_limit),
        },
        fetchPolicy: "network-only",
      });
    }
  };

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <pre
        style={{
          fontSize: "10px",
        }}
      >
        <table className="w-11/12 mx-auto capitalize">
          <thead className="bg-[#F0F6FD]">
            <tr>
              <th className="text-left text-N60 p-2 py-4">Organization Name</th>
              <th className="text-left text-N60 p-2 py-4">Type</th>
              <th className="text-left text-N60 p-2 py-4">Tin Number</th>
              <th className="text-left text-N60 p-2 py-4">Business Licence</th>
            </tr>
          </thead>
          <tbody className="alternate-bg text-base">
            <tr>
              <td className="px-2 py-4 font-semibold">
                {row?.original?.organization?.name}
              </td>
              <td className="px-2 py-4 font-semibold">
                {row?.original?.organization?.organization_type?.name}
              </td>
              <td className="px-2 py-4 font-semibold">
                <span className="bg-Prime90/40 border px-2 py-1 rounded border-prime50 text-primary">
                  {row?.original?.organization?.tin_number}
                </span>
              </td>
              <td className="px-2 py-4 font-semibold">
                <div className="flex flex-col text-xs">
                  {row?.original?.organization?.business_licence_number}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </pre>
    ),
    []
  );

  const handleAssign = (c) => {
    console.log(c);
    // Set loading state for the specific row
    setLoadingRows((prevLoadingRows) => [...prevLoadingRows, c]);
    console.log("rows:", loadingRows);
    console.log("rows:___", loadingRows.includes(c));
    console.log(setLoadingRows((prevLoadingRows) => [...prevLoadingRows, c]));

    assign({ variables: { id: c } }).then((re) => {
      if (re.errors) {
        toast.error("Permit not Assigned");
      } else {
        toast.success("Assigned for FInance and Doc checker");
        getDataFromServer({ fetchPolicy: "network-only" });
        setLoadingRows((prevLoadingRows) =>
          prevLoadingRows.filter((rowId) => rowId !== c)
        );
      }
    });
  };

  const handleSearch = (r) => {
    console.log("search value", r);
  };

  const handleSelectedFilterOption = (_value) => {
    setSelectedValue(_value);
  };

  // ---------------------------------------------------------------SEARCH----------------------------------
  const [searchExpatByOrg, {}] = useLazyQuery(EXPAT_BY_ORGANZATION, {
    variables: {
      limit: limit,
      offset: offset * limit,
    },
    onCompleted: (d) => {
      setWorkPermits(d.expatriates);
    },
    onError: (er) => console.log(er),
  });

  const [getAssignedExpat, {}] = useLazyQuery(
    GET_SEARCH_ASSIGNED_WORK_PERMITS,
    {
      onCompleted: (d) => {
        setAllAssignedPermit(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );
  const onFilterExpat = (_docID) => {
    setWorkPermits([]);
    setAllAssignedPermit([]);
    if (currentRole === roles.WP_DESK_HANDLER) {
      searchExpatByOrg({
        variables: {
          orgID: _docID,
        },
      });
    } else {
      getAssignedExpat({
        variables: {
          orgID: _docID,
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
    }
  };

  const onResetFilter = () => {
    setSelectedValue(null);
    if (currentRole === roles.WP_DESK_HANDLER) {
      getDataFromServer();
    } else {
      getAllAssignedPermits();
    }
  };

  /**
   * @description update work permit
   */
  const [updatePermit, { loading: renewalLoading, error: errorLoading }] =
    useMutation(UPDATE_RENEWAL_PERMIT, {
      onCompleted: () => {
        toast.success("Work Permit Renewed successfully!");
        setShow(false);
      },
      onError: (er) => console.log(er),
    });

  const handleGenerateLetter = (e) => {
    console.log("renewal generate", e);
    updatePermit({
      variables: {
        emr: queryName,
        issue_date: e.requestedDate,
        expired_date: e.expiryDate,
      },
    });
  };

  const handlePreview = (id) => {
    if (id) {
      navigate(`/permit/d/${id}`);
    }
  };

  /**
   * @description filter emr generator
   * @param {*} e
   */
  const handleEMRGeneratorHandlerFilter = (e) => {
    console.log("emr:", e);
    if (e) {
      searchPermitEMR({
        variables: {
          emr: e,
        },
      });
    }
  };

  const handleDocumentHandlerFilter = (e) => {
    setCurrentTab("pending");
    if (e) {
      searchPermitDocumentChecker({
        variables: {
          searchVal: e?.filterValue,
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
    }
  };

  const resetFilter = () => {
    reset();
    if (currentRole === roles.WP_DESK_HANDLER) {
      getDataFromServer();
    }
    if (currentRole === roles.WP_EMR_GENERATOR) {
      getRenewalVerifiedPermits();
    } else {
      getAllAssignedPermits();
    }
  };

  //search for Finance (filter)
  const [searchFinance, {}] = useLazyQuery(
    SEARCH_EXPATIATES_PERMITS_FILTER_DOCUMENT_CHECKER_FINANCE,
    {
      onCompleted: (d) => {
        setPendingList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => console.log(er),
    }
  );

  const handleFianceFilter = (e) => {
    if (e) {
      searchFinance({
        variables: {
          searchVal: e?.filterValue,
          permit: WORK_PERMIT_TYPES.RENEWAL,
          employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        },
      });
    }
  };

  /**
   * @description filter table based on their current role
   * @param {*} e
   */
  const handleFilter = (e) => {
    console.log("filter box data: ", e);
    switch (currentRole) {
      case roles.WP_DESK_HANDLER:
        // Update table for DESK Handler
        // handleDeskHandlerFilter(e);
        setCurrentTab("not_assigned");
        searchRenewalPermit({
          variables: {
            searchValue: e?.filterValue,
          },
        });
        console.log("desk handler filter");
        break;
      case roles.WP_DOC_CHECKER:
        // Update table for Document Checker
        handleDocumentHandlerFilter(e);
        console.log("doc checker filter");
        break;
      case roles.WP_FINANCE:
        // Update table for Finance Checker
        // handleFinanceHandlerFilter(e);
        handleFianceFilter(e);
        console.log("finance filter");
        break;
      case roles.WP_EMR_GENERATOR:
        // Update table for EMR Generator Checker
        handleEMRGeneratorHandlerFilter(e?.filterValue);
        console.log("emr generator filter");
        break;

      default:
        break;
    }
  };

  // ==============================DOCUMENT CHECKER===============================================
  /**
   * @description get Total count for assigned user
   * @access DOC:checker | finance
   */
  const [getTotalAssignedPermits, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        permit: WORK_PERMIT_TYPES.RENEWAL,
      },
      onCompleted: (d) => {
        // setTotalRenewalAssignedPermit(
        //   d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        // );
        setCountPending(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );
  // Count Approved permit (DOC Checker)
  const [getTotalAssignedPermitsApproved, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        checkerStatus: true,
        permit: WORK_PERMIT_TYPES.RENEWAL,
      },
      onCompleted: (d) => {
        setCountApproval(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );
  //Count Rejected permits (DOC Checker)
  const [getTotalAssignedPermitsRejected, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS,
    {
      variables: {
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
        checkerStatus: false,
        permit: WORK_PERMIT_TYPES.RENEWAL,
      },
      onCompleted: (d) => {
        setCountRejected(
          d?.assigned_workpermit_reviewers_aggregate?.aggregate?.count
        );
      },
    }
  );

  // ==============================================DOCUMENT CHECKER==================================================
  /**
   * @description get permit for assigned user
   * @access doc:Checker | finance
   */
  const [getAllAssignedPermits, { loading: permitLoading }] = useLazyQuery(
    GET_ALL_ASSIGNED_WORK_PERMITS,
    {
      variables: {
        limit: Number(pending_limit),
        offset: pending_offset * Number(pending_limit),
        permit: WORK_PERMIT_TYPES.RENEWAL,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        setPendingList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );

  /**
   * @description get all approved permits
   */
  const [getAllApprovedPermits, { loading: approvedLoading }] = useLazyQuery(
    DOCUMENT_ALL_APPROVED_WORK_PERMITS,
    {
      variables: {
        limit: Number(approved_limit),
        offset: approved_offset * Number(approved_limit),
        checkerStatus: true,
        permit: WORK_PERMIT_TYPES.RENEWAL,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        setApprovedList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );

  /**
   * @description get all rejected permits
   */
  const [getAllRejectedPermits, { loading: rejectedLoading }] = useLazyQuery(
    DOCUMENT_ALL_APPROVED_WORK_PERMITS,
    {
      variables: {
        limit: Number(rejected_limit),
        offset: rejected_offset * Number(rejected_limit),
        checkerStatus: false,
        permit: WORK_PERMIT_TYPES.RENEWAL,
        employeeID: JSON.parse(localStorage.getItem("work-permit"))?.userID,
      },
      onCompleted: (d) => {
        console.log("rejected:", d?.assigned_workpermit_reviewers);
        setRejectedList(d?.assigned_workpermit_reviewers);
      },
      onError: (er) => {
        console.log(er);
      },
    }
  );

  // ===================================================END DOCUMENT CHECKER===========================================

  // =====================================================FIANCE=====================================================
  /**
   * @description verify document Bank graphQl query
   */
  const [verify_doc, { fin_data, loading: signInLoading }] = useMutation(
    VERIFY_EXPATRIATE_DOCUMENT,
    {
      onError(err) {
        const error = `${err}`.split(":").reverse()[0];
        console.log(error.trim());
      },
      onCompleted(d) {
        console.log(d);
      },
    }
  );

  /**
   * @description reject bank slip
   * @access wp:finance
   */
  const handleReject = async (w) => {
    console.log("rejecte document", w, modalData);
    await verify_doc({
      variables: {
        id: modalData?.id,
        review_status: false,
        review_note: w?.message,
      },
    }).then(() => {
      // toast.SuccessToast('Finance Docuemnt Verified Successfully')
      toast.warn("Finance Document Rejected!");
      handleClose();
    });
  };

  /**
   * @description handle verify button Click on finance
   * @access wp:finance
   */
  const handleVerify = async () => {
    await verify_doc({
      variables: { id: modalData?.id, review_status: true },
    }).then(() => {
      handleClose();
      toast.success("Finance Document verified successfully");
    });
  };
  // ====================================================END FINANCE====================================================

  // ========================================================EMR====================================================
  const [getRenewalVerifiedPermits, { loading: emrLoading }] = useLazyQuery(
    APPROVED_EXPATIATES_PERMITS_RENEWAL,
    {
      variables: {
        limit: Number(pending_limit),
        offset: pending_offset * Number(pending_limit),
      },
      onCompleted: (d) => {
        setPendingList(d?.expatriate_work_permits);
      },
      onError: (er) => console.log(er),
    }
  );
  /**
   * @description get total permits (renewal only all data for EMR generator)
   */
  const [getTotalAssignedPermitsAll, {}] = useLazyQuery(
    TOTAL_ASSIGNED_WORK_PERMIT_ALL,
    {
      variables: {
        permit: WORK_PERMIT_TYPES.RENEWAL,
      },
      onCompleted: (d) => {
        console.log(
          "emr count: ",
          d?.expatriate_work_permits_aggregate?.aggregate?.count
        );
        setRenewalTotal(d?.expatriate_work_permits_aggregate?.aggregate?.count);
      },
    }
  );
  // ========================================================END EMR====================================================

  // ======================================================PAGINATION==============================================
  /**
   * @description handle previous page
   * @access all
   */
  const handlePreviousPageEMR = () => {
    console.log("clicked prev");
    if (pending_offset > 0) {
      setPendingOffset(pending_offset - 1);
    }
  };
  const handleNextPageEMR = () => {
    console.log("clicked next", pending_offset);
    setPendingOffset(pending_offset + 1);
  };

  const handlePageChangeEMR = (newNum) => {
    console.log("clicked page", newNum);
    setPendingOffset(newNum);
  };
  const handlePageLimitEMR = (val) => {
    console.log("clicked limit", val);
    setPendingLimit(val);
  };

  /**
   * approved pagination
   */
  const handlePreviousPageApproved = () => {
    if (approved_offset > 0) {
      setApprovedOffset(approved_offset - 1);
    }
  };
  const handleNextPageApproved = () => {
    setApprovedOffset(approved_offset + 1);
  };

  const handlePageChangeApproved = (newNum) => {
    setApprovedOffset(newNum);
  };
  const handlePageLimitApproved = (val) => {
    setApprovedLimit(val);
  };

  const handlePreviousPage = () => {
    if (
      currentRole === roles.WP_DOC_CHECKER ||
      currentRole === roles.WP_FINANCE
    ) {
      if (selectedTab === "pending") {
        setPendingOffset(pending_offset - 1);
      }
      if (selectedTab === "approved") {
        setApprovedOffset(approved_offset - 1);
      }
      if (selectedTab === "rejected") {
        setRejectedOffset(rejected_offset - 1);
      }
    }
  };
  /**
   * @description handle next page
   * @access all
   */
  const handleNextPage = () => {
    if (
      currentRole === roles.WP_DOC_CHECKER ||
      currentRole === roles.WP_FINANCE
    ) {
      if (selectedTab === "pending") {
        setPendingOffset(pending_offset + 1);
      }
      if (selectedTab === "approved") {
        setApprovedOffset(approved_offset + 1);
      }
      if (selectedTab === "rejected") {
        setRejectedOffset(rejected_offset + 1);
      }
    }
  };
  /**
   * @description handle page change
   * @param {*} newOffset
   * @access all
   */
  const handlePageChange = (newOffset) => {
    if (
      currentRole === roles.WP_DOC_CHECKER ||
      currentRole === roles.WP_FINANCE
    ) {
      if (selectedTab === "pending") {
        setPendingOffset(newOffset - 1);
      }
      if (selectedTab === "approved") {
        setApprovedOffset(newOffset - 1);
      }
      if (selectedTab === "rejected") {
        setRejectedOffset(newOffset - 1);
      }
    }
    // else {
    //   setOffset(newOffset - 1);
    // }
  };

  const handlePageLimit = (_limit) => {
    if (
      currentRole === roles.WP_DOC_CHECKER ||
      currentRole === roles.WP_FINANCE
    ) {
      if (selectedTab === "pending") {
        setPendingLimit(_limit);
      }
      if (selectedTab === "approved") {
        setApprovedLimit(_limit);
      }
      if (selectedTab === "rejected") {
        setRejectedLimit(_limit);
      }
    }
  };
  // ======================================================END PAGINATION=================================================
  return (
    <div className="pb-20">
      <FilterBox
        placeholder={`${
          currentRole === roles.WP_EMR_GENERATOR
            ? "EMR Number"
            : currentRole === roles.WP_FINANCE
            ? "Reference Number, Tin Number and passport"
            : currentRole === roles.WP_DOC_CHECKER
            ? "Tin Number, Passport Number or First Name"
            : "First Name and Passport Number"
        }`}
        handleReset={resetFilter}
        handleFilterData={handleFilter}
      />

      {/* Document Checker */}
      {[roles.WP_DOC_CHECKER, roles.WP_FINANCE].includes(currentRole) && (
        <>
          <Can I={"action"} a={"filterTab"}>
            {() => (
              <div
                className="my-4 inline-flex rounded-md shadow-sm"
                role="group"
              >
                <button
                  type="button"
                  onClick={() => setSelectedTab("pending")}
                  className={`${
                    selectedTab === "pending"
                      ? "bg-yellow30 text-white hover:opacity-75 border-yellow40 "
                      : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  } px-4 py-2 text-sm font-medium text-gray-900 border  rounded-l-lg `}
                >
                  Pending
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedTab("rejected")}
                  className={`${
                    selectedTab === "rejected"
                      ? "bg-red30 text-white hover:opacity-75 border-red20 "
                      : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  } px-4 py-2 text-sm font-medium text-gray-900 border `}
                >
                  Rejected
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedTab("approved")}
                  className={`${
                    selectedTab === "approved"
                      ? "bg-green30 text-white hover:opacity-75 border-green40 "
                      : "bg-white border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  } px-4 py-2 text-sm font-medium text-gray-900 border rounded-r-lg `}
                >
                  Approved
                </button>
              </div>
            )}
          </Can>

          <Table
            columns={
              currentRole === roles.WP_DOC_CHECKER
                ? ApplicantColumns.documentHandlerApplicantColumn(
                    navigate,
                    handleReviewStatus
                  )
                : ApplicantColumns.financeHandlerApplicantColumn(
                    handleReviewStatus,
                    openModal
                  )
            }
            tableData={
              selectedTab === "pending"
                ? pendingRenewalList
                : selectedTab === "approved"
                ? approvedRenewalList
                : rejectedRenewalList
            }
            loading={permitLoading}
            offset={
              selectedTab === "pending"
                ? pending_offset
                : selectedTab === "approved"
                ? approved_offset
                : rejected_offset
            }
            filterOptions={filterOptionTable}
            limit={
              selectedTab === "pending"
                ? pending_limit
                : selectedTab === "approved"
                ? approved_limit
                : rejected_limit
            }
            error={error}
            handleRefersh={handleRefershTable}
            totalPages={
              selectedTab === "pending"
                ? pendingCount
                : selectedTab === "approved"
                ? approvalCount
                : rejectedCount
            }
            handleNextPage={handleNextPage}
            handlePageChange={handlePageChange}
            handlePreviousPage={handlePreviousPage}
            handlePageLimit={handlePageLimit}
            onFilterChange={handleSelectedFilterOption}
            renderRowSubComponent={renderRowSubComponent}
            title={"Applicant:  Renewal Work Permit Applicants"}
            headerClassess="bg-primary"
            pageCount={pageCount}
          />
        </>
      )}

      {/* DESK Handler ✅*/}
      {currentRole === roles.WP_DESK_HANDLER && (
        <>
          <div className="mb-5 text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-200">
            <ul className="flex flex-wrap -mb-px">
              <li className="mr-2">
                <button
                  onClick={() => setCurrentTab("not_assigned")}
                  className={`${
                    currentTab === "not_assigned"
                      ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      : "hover:text-gray-600 hover:border-gray-300 border-transparent"
                  } inline-block p-4 border-b-2 rounded-t-lg`}
                >
                  Not Assigned
                </button>
              </li>
              <li className="mr-2">
                <button
                  onClick={() => setCurrentTab("assigned")}
                  className={`${
                    currentTab === "assigned"
                      ? "text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                      : "hover:text-gray-600 hover:border-gray-300 border-transparent"
                  } inline-block p-4 border-b-2 rounded-t-lg`}
                  aria-current="page"
                >
                  Assigned
                </button>
              </li>
            </ul>
          </div>

          {currentTab === "not_assigned" && (
            <Table
              columns={ApplicantColumns.deskHandlerRenewalApplicantColumn(
                navigate,
                handleAssign,
                loadingRows,
                handlePopUp
              )}
              tableData={pendingRenewalList}
              loading={loading}
              error={error}
              offset={pending_offset}
              limit={pending_limit}
              filterOptions={filterOptionTable}
              totalPages={notAssignedTotalPermit}
              handleNextPage={handleNextPageEMR}
              handlePageChange={handlePageChangeEMR}
              handlePreviousPage={handlePreviousPageEMR}
              handleRefersh={handleRefershTable}
              handlePageLimit={handlePageLimitEMR}
              onSearchChange={handleSearch}
              onFilterChange={handleSelectedFilterOption}
              renderRowSubComponent={renderRowSubComponent}
              title={"Applicant:  Work Permit Applcants"}
              headerClassess="bg-primary"
              pageCount={pageCount}
            />
          )}

          {currentTab === "assigned" && (
            <Table
              columns={ApplicantColumns.deskHandlerRenewalApplicantColumn(
                navigate,
                handleAssign,
                loadingRows,
                handlePopUp
              )}
              tableData={approvedRenewalList}
              loading={loading}
              error={error}
              offset={approved_offset}
              limit={approved_limit}
              filterOptions={filterOptionTable}
              totalPages={totalExpatList}
              handleNextPage={handleNextPageApproved}
              handlePageChange={handlePageChangeApproved}
              handlePreviousPage={handlePreviousPageApproved}
              handleRefersh={handleRefershTable}
              handlePageLimit={handlePageLimitApproved}
              onSearchChange={handleSearch}
              onFilterChange={handleSelectedFilterOption}
              renderRowSubComponent={renderRowSubComponent}
              title={"Applicant:  Work Permit Applcants"}
              headerClassess="bg-primary"
              pageCount={pageCount}
            />
          )}
        </>
      )}

      {/* EMR Generator ✅ */}
      {currentRole === roles.WP_EMR_GENERATOR && (
        <>
          <Table
            columns={
              VerifiedApplicants.emrColumnsRenewal(
                navigate,
                handlePopUp,
                handlePreview
              ) //to be replaced
            }
            tableData={pendingRenewalList}
            loading={emrLoading}
            offset={pending_offset}
            filterOptions={filterOptionTable}
            limit={pending_limit}
            error={error}
            handleRefersh={handleRefershTable}
            totalPages={renewalTotal}
            handleNextPage={handleNextPageEMR}
            handlePageChange={handlePageChangeEMR}
            handlePreviousPage={handlePreviousPageEMR}
            handlePageLimit={handlePageLimitEMR}
            onFilterChange={handleSelectedFilterOption}
            renderRowSubComponent={renderRowSubComponent}
            title={"Work Permits:  Renewal Work Permit Applicants"}
            headerClassess="bg-primary"
            pageCount={pageCount}
          />
        </>
      )}

      {/* Modal  */}
      <div className="fixed top-0 left-0 right-0 overflow-hidden">
        {show ? (
          <div
            id="defaultModal"
            className="w-full h-screen bg-black/15 fixed backdrop-blur-md flex justify-center items-center p-4 overflow-x-hidden overflow-y-auto md:inset-0"
          >
            <div class="relative w-full max-w-2xl max-h-full">
              <div class="relative bg-white rounded-lg shadow">
                <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-300">
                  <h3 class="text-xl font-semibold text-gray-900">
                    Renewal Permit
                  </h3>
                  <button
                    onClick={() => setShow(false)}
                    type="button"
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="defaultModal"
                  >
                    <svg
                      class="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                <FormProvider
                  onSubmithandler={handleSubmit(handleGenerateLetter)}
                >
                  <div class="p-6 space-y-6">
                    <div className="grid grid-cols-2 gap-4">
                      <CInputField
                        name="emr_number"
                        register={register}
                        errors={errors}
                        defaultValue={queryName}
                        disabled
                        // validation={{
                        //   required: true,
                        // }}
                        type="text"
                        label="EMR Number"
                        classes={"col-span-2"}
                        placeholder=""
                      />
                      <CInputField
                        name="requestedDate"
                        register={register}
                        errors={errors}
                        validation={{
                          required: true,
                        }}
                        type="date"
                        label="Requested Date"
                      />
                      <CInputField
                        name="expiryDate"
                        register={register}
                        errors={errors}
                        validation={{
                          required: true,
                        }}
                        type="date"
                        label="Expiry Date"
                      />
                    </div>
                  </div>
                  <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-300">
                    <CButton
                      data-modal-hide="defaultModal"
                      type="submit"
                      btnLabel={"Save"}
                      loading={renewalLoading}
                      classes="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    />
                    <button
                      data-modal-hide="defaultModal"
                      type="button"
                      onClick={() => setShow(false)}
                      className="text-gray-500 mt-5 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Cancel
                    </button>
                  </div>
                </FormProvider>
              </div>
            </div>
          </div>
        ) : (
          // <BlackListModal id={queryName} handleClick={handlePopUp} />
          ""
        )}
      </div>

      {/* Financial Modal */}
      {showModal && (
        <FinanceDocument
          handleReject={handleReject}
          handleVerify={handleVerify}
          data={modalData}
          handleClick={handleClose}
        />
      )}
    </div>
  );
};

export default authorize("view")(Renewal);
