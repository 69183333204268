import React, { useEffect, useState } from "react";
import PDFReader from "../pdf/PDFReader";

const LetteDocumentViewerDrawer = ({
  imageUrl,
  loading,
  handleCloseDrawer,
}) => {
  return (
    <div
      style={{ background: "rgba(0,0,0,0.9)" }}
      className="z-50 fixed top-0 left-0 h-screen w-full flex items-center justify-center"
    >
      <div
        id="drawer-body-scrolling"
        className="fixed top-0 right-full md:right-2/4 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-full md:w-2/4 dark:bg-gray-800"
        tabIndex="-1"
        aria-labelledby="drawer-body-scrolling-label"
      >
        <button
          type="button"
          data-drawer-hide="drawer-body-scrolling"
          aria-controls="drawer-body-scrolling"
          onClick={handleCloseDrawer}
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        {loading ? (
          <div className="ml-20 mt-28 text-white">Loading....</div>
        ) : (
          <>
            {" "}
            {imageUrl ? (
              <div className="py-4 overflow-y-auto mt-4">
                <div className="border-2 p-4 mb-2 border-green rounded-md w-full">
                  <h5 className="font-semibold text-gray-100">
                    Letter Of Delegation is Approved{" "}
                  </h5>
                </div>

                <PDFReader pdfData={imageUrl} />
              </div>
            ) : (
              <div className="py-4 overflow-y-auto mt-4">
                <div className="border-2 p-4 mb-2 border-red rounded-md w-full">
                  <h5 className="font-semibold text-gray-100">
                    Letter Of Delegation is not Approved{" "}
                  </h5>
                </div>
                <h5 className=" text-red">
                  Object reference not set to an instance of an object.
                </h5>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LetteDocumentViewerDrawer;
