import React from "react";
import { VscFilePdf } from "react-icons/vsc";
import { MdOutlineDelete } from "react-icons/md";
import CSelectField from "../Forms/Fields/CSelectField";
import FormProvider from "../Forms/Fields/FormProvider";
import { useForm } from "react-hook-form";
import CButton from "../Forms/CButton";
import { useLocalStore } from "../../store";

const ChooseDocumentModal = ({
  optionData,
  handleCancel,
  files,
  setFiles,
  onFileUpload,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue, // to programmatically set the value of additionalInfo if needed
  } = useForm();
  const { documentTypes, orgDocumentTypes } = useLocalStore();
  const selectDropDownValue = watch("selectedCategory");

  const handleFileChange = (e) => {
    if (selectDropDownValue) {
      const _file = e.target.files[0];
      const newFiles = [...files];

      const t = {
        _file,
        title: getDocumentName(selectDropDownValue),
        category: "ef0e53f7-fdb7-48fb-b0fc-f19ca3a4bc64",
        document_type_id: selectDropDownValue,
      };

      const existingFileIndex = newFiles.findIndex(
        (f) => f.document_type_id === selectDropDownValue
      );

      if (existingFileIndex !== -1) {
        newFiles[existingFileIndex] = t;
      } else {
        newFiles.push(t);
      }

      setFiles(newFiles);
    }
  };

  const getDocumentName = (e) => {
    return documentTypes.find((i) => i.id === e)?.name || "";
  };

  const handleRemoveFile = (categoryId) => {
    const newFiles = files.filter(
      (file) => file.document_type_id !== categoryId
    );
    setFiles(newFiles);
  };

  const onSubmit = (data) => {
    console.log(data);
    // Pass the form data (including additionalInfo) to the onFileUpload function
    onFileUpload(data);
  };

  return (
    <>
      <div
        className="z-50 fixed top-0 left-0 h-screen w-full flex items-center justify-center"
        style={{ background: "rgba(0,0,0,0.8)" }}
      >
        <div className="w-full md:w-3/5 h-max rounded-xl bg-white flex flex-col justify-center items-center relative py-6 px-10">
          <div className="border-b-2 pt-3 pb-4 w-full">
            <h2 className="self-start font-semibold text-lg">
              File Upload Modal
            </h2>
          </div>
          <div className="w-full flex flex-col gap-4">
            <FormProvider onSubmithandler={handleSubmit(onSubmit)}>
              <div className="w-full">
                <CSelectField
                  name="selectedCategory"
                  register={register}
                  errors={errors}
                  validation={{ required: "Professional title is required" }}
                  options={optionData.map((item) => ({
                    ...item,
                    value: item.id,
                    disabled: files.some(
                      (file) => file.document_type_id === item.id
                    ),
                  }))}
                  label=""
                  placeholder=""
                />
              </div>

              {/* Conditionally render the text field if category is "Letter of Delegation" */}
              {selectDropDownValue &&
                getDocumentName(selectDropDownValue) ===
                  "Letter of Delegation" && (
                  <div className="w-full mt-4">
                    <label htmlFor="textField" className="text-sm font-medium">
                      Document Number
                    </label>
                    <input
                      type="text"
                      id="textField"
                      {...register("additionalInfo", {
                        required: "This field is required",
                      })}
                      className="w-full p-2 border border-gray-300 rounded mt-1"
                    />
                    {errors.additionalInfo && (
                      <p className="text-xs text-red-500">
                        {errors.additionalInfo.message}
                      </p>
                    )}
                  </div>
                )}

              <div className="flex items-start flex-wrap md:flex-nowrap gap-4 h-auto md:h-64 w-full">
                <div className="h-full w-full md:w-1/2 flex justify-center items-center border-dashed border-2 mt-4 bg-Prime99/30 rounded-lg p-6">
                  <div className="h-full w-full border-dashed border-2 border-N99 rounded-lg flex justify-center items-center cursor-pointer relative">
                    <p className="text-xs font-light text-N90 text-center">
                      Click to browse or <br /> drag and drop your files
                    </p>
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => handleFileChange(e)}
                      className="h-full w-full opacity-0 absolute top-0 left-0 cursor-pointer"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-2 w-full md:w-1/2 h-full overflow-auto noScrollBar">
                  {files.map((item, index) => (
                    <div
                      key={index}
                      className="border-b border-N99/50 p-4 flex justify-between items-center w-full"
                    >
                      <div className="flex gap-2 items-center">
                        <VscFilePdf />
                        <div>
                          <p className="text-xs text-N50">{item.title}.pdf</p>
                          <p className="text-[10px] text-N50">
                            {(item._file?.size / 1000).toFixed(2)} KB
                          </p>
                        </div>
                      </div>

                      <MdOutlineDelete
                        onClick={() => handleRemoveFile(item.document_type_id)}
                        className="text-2xl rounded p-1 bg-red text-white cursor-pointer hover:scale-110 transition-transform ease-in-out duration-300"
                      />
                    </div>
                  ))}
                  {files.length === 0 && (
                    <div className="flex justify-center items-center min-h-max">
                      No File Selected Yet
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full flex gap-4 items-center justify-end mt-4">
                <button
                  onClick={handleCancel}
                  className="w-44 bg-Prime99/50 text-primary mt-4 text-sm py-2 rounded hover:bg-Prime99 transition-all ease-in-out duration-300"
                >
                  Cancel
                </button>
                <CButton
                  type={"submit"}
                  btnLabel={"Done"}
                  classes="w-44 bg-primary text-white text-sm py-2 rounded hover:bg-prime30 transition-all ease-in-out duration-300"
                />
              </div>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseDocumentModal;
