"use client";

import { useEffect, useState } from "react";
import { BsBuilding, BsUpcScan } from "react-icons/bs";
import { TfiMenuAlt } from "react-icons/tfi";
import InfoCard from "../../components/cards/InfoCard";
import {
  MdAccessTime,
  MdCancel,
  MdCheckCircle,
  MdEmail,
  MdErrorOutline,
  MdFlag,
  MdHouse,
  MdPhone,
  MdPictureAsPdf,
} from "react-icons/md";
import WorkPermitCard from "../../components/cards/WorkPermitCard";
import CreatorCard from "../../components/cards/CreatorCard";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_ORGANIZATION_BYID,
  GET_ORGANIZATION_RENEWAL,
  SEARCH_PREV_ORGANIZATION,
} from "../../graph-query/queries";
import { useParams } from "react-router-dom";
import {
  ORGANIZATION_DOCUMENT_VERIFY,
  VERIFY_ORG,
  VERIFY_RENEWAL_ORG,
} from "../../graph-query/mutation";
import { toast } from "react-toastify";
import StatusCard from "../../components/StatusCard";
import { useForm } from "react-hook-form";
import { BiBuilding } from "react-icons/bi";
import { FormProvider } from "../../components/Forms/Fields";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { useRef } from "react";
import { trackPromise } from "react-promise-tracker";
import RejectModal from "../../components/modals/RejectModal";
import DocumentViewerDrawer from "../../components/modals/DocumentViewerDrawer";
import Button from "../../components/common/Button";
import { getImageUrl } from "../../components/common/imageUtils";
import axios from "axios";

const Organization = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [organizationData, setOrganizationData] = useState();
  const [organizationDataRenewal, setOrganizationDataRenewal] = useState();
  const [currentTab, setCurrentTab] = useState("detail");
  const [blackListResult, setBlackListResult] = useState([]);
  const [taxData, setTaxDta] = useState();
  const [busnessData, setbusnessData] = useState();
  const [cemmercialData, setcemmercialData] = useState();
  const sendTaxClearanceRequest = async (tinNumber) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_INT_API}/mor/tax_payer_info?tin=${tinNumber}`
      );
      console.log("Tax clearance response:", response.data);
      setTaxDta(response?.data?.taxPayerDetails);
      toast.success("Tax clearance request sent successfully");
      return response.data;
    } catch (error) {
      setTaxDta();
      console.error("Error sending tax clearance request:", error);
      toast.error(" Not Found: Nothing clearance with this TIN No.");
      return null;
    }
  };
  const fetchTaxData = async (business_licence_number, tinNumber) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_INT_API}/motri/getByTinInfo?Lang=amTin=${tinNumber}&LicenseNo=${business_licence_number}`,
        {
          headers: {
            "EGP-Referer": "000229ce-9012-4fef-8062-8231864b35aa",
          },
        }
      );

      console.log(response);
      if (response?.data?.data !== null) {
        toast.success("Business License  request sent successfully");
        setbusnessData(response.data);
      } else {
        setbusnessData("");
        toast.error(
          " Not Found: Nothing clearance with this Business License."
        );
      }
    } catch (err) {
      setbusnessData("");
      toast.error(" Not Found: Nothing clearance with this Business License.");
      console.error(err);
    }
  };
  const fetchcemmercial = async (tinNumber) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_INT_API}/Registration/GetRegistrationInfoByTin/${tinNumber}/en`,
        {
          headers: {
            "EGP-Referer": "000229ce-9012-4fef-8062-8231864b35aa",
          },
        }
      );
      toast.success("cemmercial Data  request sent successfully");

      setcemmercialData(response.data);
    } catch (err) {
      toast.error(" Not Found: Nothing clearance with this cemmercial Data.");
      console.error(err);
    }
  };

  const [openLightBox, setOpenLightBox] = useState(false);

  const { id } = useParams();

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const {
    register: registerDoc,
    watch: watchDoc,
    formState: { errors: errorDoc },
    handleSubmit: handleSubmitDoc,
  } = useForm();

  const {
    register: blackRegister,
    watch: blackWatch,
    handleSubmit: handleBlackListSubmit,
    reset: blackReset,
    formState: { errors: blackListError },
  } = useForm();

  useEffect(() => {
    if (organizationData !== undefined) {
      // fetchcemmercial("0005488826");
      // sendTaxClearanceRequest("0005488826");
      // fetchTaxData("LK/AA/14/707/4514098/2015", "0005488826");
      sendTaxClearanceRequest(organizationData?.tin_number);
      fetchcemmercial(organizationData?.tin_number);
      fetchTaxData(
        organizationData?.business_licence_number,
        organizationData?.tin_number
      );
    }
  }, [organizationData]);
  const searchdValue = blackWatch("blackListSearch");
  const zoomRef = useRef(null);

  // search black list
  const [searchBlackList, { loading: blackLoading }] = useLazyQuery(
    SEARCH_PREV_ORGANIZATION,
    {
      onCompleted: (d) => {
        setBlackListResult(d?.prev_organization_black_lists);
      },
    }
  );

  const reviewText = watch("review_note");
  const reviewTextDoc = watchDoc("review_note_doc");

  const [getData, {}] = useLazyQuery(GET_ORGANIZATION_BYID, {
    variables: {
      id: id,
    },
    onCompleted: (data) => {
      setOrganizationData(data?.organizations[0]);
    },
    onError: (er) => console.log(er),
  });
  const [getRenewalData, {}] = useLazyQuery(GET_ORGANIZATION_RENEWAL, {
    variables: {
      orgID: id,
    },
    onCompleted: (data) => {
      setOrganizationDataRenewal(data?.organization_renewal[0]);
    },
    onError: (er) => console.log(er),
  });

  const [verifyOrgDocuments, {}] = useMutation(ORGANIZATION_DOCUMENT_VERIFY);
  const [verifyOrganization, {}] = useMutation(VERIFY_ORG, {
    onError: (err) => toast.error("Something is wrong, try again"),
  });
  const [verifyRenewalOrganization, {}] = useMutation(VERIFY_RENEWAL_ORG, {
    onError: (err) => toast.error("Something is wrong, try again"),
  });

  /**
   * @description Handle Organization overall verification process
   */
  const handleVerify = async () => {
    console.log("clicked");
    if (organizationData?.is_renewed) {
      await verifyRenewalOrganization({
        variables: {
          id: id,
          status: true,
          note: "",
        },
        onCompleted: (res) => {
          getData({ fetchPolicy: "network-only" });
          toast.success("Organization Verifed successfully");
        },
      });
    } else {
      await verifyOrganization({
        variables: {
          id: id,
          status: true,
          note: "",
        },
        onCompleted: (res) => {
          getData({ fetchPolicy: "network-only" });
          toast.success("Organization Verifed successfully");
        },
      });
    }
  };

  /**
   * @description Handle Organization overall rejection process
   * @param {*} e
   */
  const handleReject = async (e) => {
    // TODO reject logic here
    console.log(e);
    if (organizationData?.is_renewed) {
      await verifyRenewalOrganization({
        variables: {
          id: id,
          status: false,
          note: e?.review_note,
        },
        onCompleted: (res) => {
          setShowModal(!showModal);
          toast.success("Organization Rejected successfully");
          getData({ fetchPolicy: "network-only" });
        },
      });
    } else {
      await verifyOrganization({
        variables: {
          id: id,
          status: false,
          note: e?.review_note,
        },
        onCompleted: (res) => {
          setShowModal(!showModal);
          toast.success("Organization Rejected successfully");
          getData({ fetchPolicy: "network-only" });
        },
      });
    }
  };

  const handleSearchBlackList = (e) => {
    searchBlackList({
      variables: {
        name: e?.blackListSearch,
      },
    });
  };

  const handleBackList = (e) => {
    if (e.keyCode === 8) {
      blackReset();
      setBlackListResult([]);
    }
  };

  /**
   * @description handle selected card document to preview for verification process
   * @param {*} w
   */
  const handleSelectedDoc = (w) => {
    console.log(w);
    setOpenDrawer(!openDrawer);
    setSelectedDocument(w);
    // setSelectedDocument(
    //   organizationData?.organization_documents
    //     ?.filter((i) => i.id === w)
    //     .map((item) => item)[0]
    // );
  };

  /**
   * @description Handle Organization Verify document
   */
  const handleOrgDocumentVerify = () => {
    verifyOrgDocuments({
      variables: {
        note: "",
        id: selectedDocument?.id,
        status: true,
      },
      onCompleted: (d) => {
        if (d?.update_organization_documents?.affected_rows >= 1) {
          toast.success("Organization Document Verified Successfully");
          getData({ fetchPolicy: "network-only" });
          setOpenDrawer(false);
        }
      },
      onError: (e) => {
        toast.error("Unable to Verified Organization Document");
      },
    });
    // setShowDocumentModal(!showDocumentModal);
  };

  /**
   * @description Handle Organization Reject document
   * @param {*} e
   */
  const handleRejectDocument = (e) => {
    console.log(e);
    verifyOrgDocuments({
      variables: {
        note: e?.review_note_doc,
        id: selectedDocument?.id,
        status: false,
      },
      onCompleted: (d) => {
        toast.success("Organization Document Rejected!!!");
        setShowDocumentModal(!showDocumentModal);
        getData({ fetchPolicy: "network-only" });
        setOpenDrawer(false);
      },
      onError: (e) => {
        toast.error("Unable to Verified Organization Document");
      },
    });
  };

  useEffect(() => {
    trackPromise(getData());
  }, []);

  useEffect(() => {
    if (organizationData && organizationData?.is_renewed) {
      trackPromise(getRenewalData());
    }
  }, [organizationData, setOrganizationData]);

  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (selectedDocument?.files?.path) {
        const url = await getImageUrl(selectedDocument?.files?.path);
        setImageUrl(url);
      }
    };

    fetchImageUrl();
  }, [selectedDocument?.files?.path]);

  return (
    <>
      <div className="flex gap-6">
        <div className="w-2/3 space-y-6">
          <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
            <div className="flex pb-4 border-b border-bN90">
              <button
                onClick={() => setCurrentTab("detail")}
                type="button"
                className={`${
                  currentTab === "detail" ? "text-primary " : ""
                } w-1/2 text-center font-medium flex gap-2 items-center justify-center`}
              >
                <TfiMenuAlt />
                <p className="text-sm">Details</p>
              </button>
              <button
                onClick={() => setCurrentTab("document")}
                type="button"
                className={`${
                  currentTab === "document" ? "text-primary " : ""
                } w-1/2 text-center flex gap-2 text-N70 items-center justify-center`}
              >
                <TfiMenuAlt />
                <p className="text-sm">Documents / Files</p>
              </button>
            </div>
          </div>
          {currentTab === "detail" && (
            <div className="bg-white p-3">
              <div className="flex items-center gap-4 justify-between">
                <div className="flex items-center gap-3">
                  <div className="p-3 bg-Prime90/20 text-primary rounded text-xl">
                    <BsBuilding />
                  </div>
                  <div>
                    <h3 className="font-medium text-N40">
                      {organizationData?.name}
                    </h3>
                    <div className="flex items-center gap-2 text-primary text-xs">
                      <BsBuilding />
                      <p>{organizationData?.sector?.name_json?.en}</p>
                    </div>
                  </div>
                </div>

                {!organizationData?.is_renewed && (
                  <div className="flex gap-3">
                    <Button
                      handleClick={() => setShowModal(true)}
                      custom="bg-red text-white rounded py-1.5 text-sm"
                    >
                      Reject Company
                    </Button>
                    <Button
                      handleClick={handleVerify}
                      disabled={
                        organizationData?.document_status ===
                          "Incomplete document" ||
                        organizationData?.review_status
                      }
                      custom="bg-primary text-white rounded py-1.5 text-sm"
                    >
                      Verify Company
                    </Button>
                  </div>
                )}
                {organizationData?.is_renewed && (
                  <div className="flex gap-3">
                    <Button
                      handleClick={() => setShowModal(true)}
                      custom="bg-red text-white rounded py-1.5 text-sm"
                    >
                      Reject Renewal
                    </Button>
                    <Button
                      handleClick={handleVerify}
                      disabled={organizationDataRenewal?.review_status}
                      custom="bg-primary text-white rounded py-1.5 text-sm"
                    >
                      Verify Renewal
                    </Button>
                  </div>
                )}
              </div>
              <div className=" space-y-8 p-6 rounded-md shadow-md">
                <div className="flex items-center gap-4">
                  <div className="flex flex-col gap-3 items-center px-4 w-1/3">
                    <div className="p-4 rounded-full bg-N99/60 w-20 h-20 flex items-center justify-center">
                      <BsBuilding className="text-primary text-3xl" />
                    </div>
                    <div className=" space-y-1">
                      <h3 className="font-semibold text-N20">
                        {organizationData?.name}
                      </h3>
                      <p className="text-N50 text-sm text-center">
                        {organizationData?.sector?.name_json?.en}
                      </p>
                    </div>
                  </div>
                  <div className=" space-y-2 px-6 w-2/3">
                    <InfoCard title="email" email={organizationData?.email}>
                      <MdEmail />
                    </InfoCard>
                    <InfoCard
                      title="phone number"
                      email={organizationData?.phone_no[0]}
                    >
                      <MdPhone />
                    </InfoCard>
                    <InfoCard
                      title="House Number"
                      email={organizationData?.house_number}
                    >
                      <MdPhone />
                    </InfoCard>
                    <InfoCard
                      title="Region"
                      email={organizationData?.region?.name_json?.en}
                    >
                      <MdHouse />
                    </InfoCard>
                    <InfoCard
                      title="Organization type"
                      email={organizationData?.organization_type?.name_json?.en}
                    >
                      <MdFlag />
                    </InfoCard>
                    {/* <InfoCard title="passport number" email={organizationData?.passport_number}>
                    <MdEmail />
                  </InfoCard> */}
                  </div>
                </div>
                <CreatorCard
                  data={organizationData?.user_info}
                  custom="bg-N99/20"
                />
              </div>
            </div>
          )}

          {currentTab === "detail" && (
            <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
              <h2 className="text-N70 capitalize font-medium">
                organization Details
              </h2>
              <div className="flex items-center gap-4">
                <div className="rounded bg-N99/30 p-3 w-1/4 space-y-1 relative">
                  <p className="text-sm text-N70 capitalize">tin number</p>
                  <h3 className="2xl:text-lg text-primary font-semibold tracking-wider">
                    {organizationData?.tin_number}
                  </h3>
                  {taxData ? (
                    <div className="absolute top-2 right-2">
                      <MdFlag className="text-green text-xl" />
                    </div>
                  ) : (
                    <div className="absolute top-2 right-2">
                      <MdFlag className="text-red text-xl" />
                    </div>
                  )}
                </div>
                <div className="rounded bg-N99/30 p-3 w-2/5 space-y-1">
                  <p className="text-sm text-N70 capitalize">
                    Business Licence Number
                  </p>
                  <h3 className="text-lg text-primary font-semibold tracking-wider">
                    {organizationData?.business_licence_number}
                  </h3>
                </div>
                <div className="rounded bg-N99/30 p-3 w-1/3 space-y-1">
                  <p className="text-sm text-N70 capitalize">
                    Local Activity Code
                  </p>
                  <h3 className="text-lg text-primary font-semibold tracking-wider">
                    {organizationData?.activity_code}
                  </h3>
                </div>
              </div>
              <div className=" space-y-1">
                <h4 className="text-sm text-N70">Activity Type</h4>
                <h4 className="text-primary font-medium">Activity Type here</h4>
              </div>
              {/* <div className="text-sm space-y-1">
                <h4 className=" text-N70">Major Activity</h4>
                <p className="text-N40">
                  Major Activity of the organizaiton Major Activity of the
                  organizaitonMajor Activity of the organizaitonMajor Activity of
                  the organizaitonMajor Activity of the organizaitonMajor Activity
                  of the organizaiton
                </p>
              </div> */}
              <div className="flex items-center gap-2">
                <p className="text-N70">Expatriates Count -</p>
                <h3 className="fon-medium text-primary font-semibold text-xl">
                  {organizationData?.expatriates_count}
                </h3>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-N70">Employee Count -</p>
                <h3 className="fon-medium text-primary font-semibold text-xl">
                  {organizationData?.local_employees_count}
                </h3>
              </div>
            </div>
          )}

          {currentTab === "document" && (
            <>
              {/* <select
                onChange={(e) => handleSelectedDoc(e.target.value)}
                name="selectedDocument"
                id=""
                className="rounded-md border-gray-600"
              >
                <option>Select Document</option>
                {organizationData?.organization_documents?.map((item) => (
                  <option value={item.id}>{item?.files?.title}</option>
                ))}
              </select> */}
              <h3 className="font-semibold text-lg">
                Documents ({organizationData?.organization_documents.length})
              </h3>
              <div className="bg-white p-4 flex gap-2 flex-wrap">
                {organizationData?.organization_documents?.map((item) => (
                  <button
                    onClick={() => handleSelectedDoc(item)}
                    type="button"
                    className={`relative ${
                      item?.review_status === true
                        ? "bg-green/30"
                        : item?.review_status === false
                        ? "bg-red/30"
                        : "bg-yellow/30"
                    } border-2 text-start w-80 max-w-full border-blue-200 rounded-md p-4 hover:opacity-70`}
                  >
                    {/* Green/Red Flag on Top-Right */}
                    {item?.files?.title === "TIN Certificate" && (
                      <span
                        className={`absolute top-2 right-2 px-2 py-1 rounded-full text-xs font-semibold ${
                          taxData
                            ? "bg-green-500 text-white"
                            : "bg-red-500 text-white"
                        }`}
                      >
                        {taxData ? (
                          <div className="text-blue-700 text-[200%]"> ✓</div>
                        ) : (
                          <div className="text-red text-[200%]"> X</div>
                        )}
                      </span>
                    )}
                    {item?.files?.title === "Business License Certificate" && (
                      <span
                        className={`absolute top-2 right-2 px-2 py-1 rounded-full text-xs font-semibold ${
                          busnessData
                            ? "bg-green-500 text-white"
                            : "bg-red-500 text-white"
                        }`}
                      >
                        {busnessData ? (
                          <div className="text-blue-700 text-[200%]"> ✓</div>
                        ) : (
                          <div className="text-red text-[200%]"> X</div>
                        )}
                      </span>
                    )}
                    {item?.files?.title === "Certificate of Registration" && (
                      <span
                        className={`absolute top-2 right-2 px-2 py-1 rounded-full text-xs font-semibold ${
                          cemmercialData
                            ? "bg-green-500 text-white"
                            : "bg-red-500 text-white"
                        }`}
                      >
                        {cemmercialData ? (
                          <div className="text-blue-700 text-[200%]"> ✓</div>
                        ) : (
                          <div className="text-red text-[200%]"> X</div>
                        )}
                      </span>
                    )}

                    <h3 className="font-semibold text-md">
                      {item?.files?.title}
                    </h3>
                    <div className="flex flex-col gap-2">
                      <small className="font-normal text-sm flex items-center gap-2">
                        <MdPictureAsPdf />
                        {item?.files?.name}
                      </small>
                      <span
                        className={`self-start ${
                          item?.review_status === true
                            ? "bg-green text-white"
                            : item?.review_status === false
                            ? "bg-red10 text-fuchsia-50"
                            : "bg-yellow30 text-white"
                        } px-2 py-0.5 rounded text-xs bg-blue-100`}
                      >
                        {item?.review_status === true
                          ? "Approved"
                          : item?.review_status === false
                          ? "Rejected"
                          : "Pending"}
                      </span>
                      {item?.files?.title === "Business License Certificate" &&
                        (busnessData ? (
                          <div className="mt-2 border-t pt-2 text-sm text-gray-600">
                            <p>
                              <strong>TradeName:</strong>{" "}
                              {busnessData.TradeName}
                            </p>
                            <p>
                              <strong>Licence Number:</strong>{" "}
                              {busnessData.LicenceNumber}
                            </p>
                            <p>
                              <strong>Date Registered:</strong>{" "}
                              {busnessData.DateRegistered}
                            </p>
                            {busnessData.RenewedFrom && (
                              <p>
                                <strong>RenewedFrom:</strong>{" "}
                                {busnessData.RenewedFrom}
                              </p>
                            )}
                            {busnessData.RenewedToDateString && (
                              <p>
                                <strong>RenewedTo:</strong>{" "}
                                {busnessData.RenewedToDateString}
                              </p>
                            )}
                          </div>
                        ) : (
                          <p className="text-red font-semibold mt-2">
                            Not Found: Nothing clearance with this Business
                            License.
                          </p>
                        ))}
                      {item?.files?.title === "Certificate of Registration" &&
                        (cemmercialData ? (
                          <div className="mt-2 border-t pt-2 text-sm text-gray-600">
                            <p>
                              <strong>BusinessName:</strong>{" "}
                              {cemmercialData.BusinessName}
                            </p>
                            <p>
                              <strong>RegNo:</strong> {cemmercialData.RegNo}
                            </p>
                            <p>
                              <strong>Date Registered:</strong>{" "}
                              {cemmercialData.RegDate}
                            </p>
                            {cemmercialData.PaidUpCapital && (
                              <p>
                                <strong>PaidUpCapital:</strong>{" "}
                                {cemmercialData.PaidUpCapital}
                              </p>
                            )}
                            {cemmercialData.LegalCondtion && (
                              <p>
                                <strong>LegalCondtion:</strong>{" "}
                                {cemmercialData.LegalCondtion}
                              </p>
                            )}
                          </div>
                        ) : (
                          <p className="text-red font-semibold mt-2">
                            Not Found: Nothing clearance with this Business
                            License.
                          </p>
                        ))}
                      {/* Tax Data Display */}
                      {item?.files?.title === "TIN Certificate" &&
                        (taxData ? (
                          <div className="mt-2 border-t pt-2 text-sm text-gray-600">
                            <p>
                              <strong>TIN:</strong> {taxData.CMP_TIN}
                            </p>
                            <p>
                              <strong>Start Date:</strong> {taxData.START_DATE}
                            </p>
                            <p>
                              <strong>End Date:</strong> {taxData.END_DATE}
                            </p>
                            <p>
                              <strong> Organization Name:</strong>{" "}
                              {taxData.REGIST_NAME}
                            </p>
                          </div>
                        ) : (
                          <p className="text-red font-semibold mt-2">
                            Not Found: Nothing clearance with this TIN No.
                          </p>
                        ))}

                      {item?.review_note && (
                        <p className="text-red">{item?.review_note}</p>
                      )}
                    </div>
                  </button>
                ))}
              </div>

              {/* <div className="flex gap-2 justify-between">
                {selectedDocument && (
                  <span className="rounded-2xl border-2 border-gray-400 px-4 py-1">
                    {selectedDocument?.review_status === true
                      ? "Verified"
                      : selectedDocument?.review_status === false
                      ? "Rejected"
                      : "Pending"}
                  </span>
                )}

                {selectedDocument && (
                  <div className="flex flex-wrap gap-2">
                    <Button
                      handleClick={() => setShowDocumentModal(true)}
                      custom={`${
                        selectedDocument?.review_status === false
                          ? "pointer-events-none opacity-40"
                          : ""
                      } bg-red text-white rounded py-1.5 text-sm`}
                    >
                      Reject Document
                    </Button>
                    <Button
                      handleClick={handleOrgDocumentVerify}
                      custom={`${
                        selectedDocument?.review_status === true
                          ? "pointer-events-none opacity-40"
                          : ""
                      } bg-primary text-white rounded py-1.5 text-sm`}
                    >
                      Verify Document
                    </Button>
                  </div>
                )}
              </div> */}
            </>
          )}
        </div>
        <Lightbox
          plugins={[Zoom]}
          open={openLightBox}
          zoom={{ ref: zoomRef }}
          on={{
            click: () => zoomRef.current?.zoomIn(),
          }}
          close={() => setOpenLightBox(false)}
          zoomProps={{
            ref: zoomRef,
            doubleClick: true,
          }}
          slides={[
            {
              src: imageUrl,
            },
          ]}
        />

        {/* black list search */}
        <div className="w-1/3 space-y-6">
          <div className="bg-white shadow-sm p-4 rounded-md">
            <div className="flex flex-col gap-3 items-center">
              <div className=" space-y-1 w-full">
                <h3 className="font-semibold mt-2 text-N20">
                  BlackList Search
                </h3>
                <FormProvider
                  onSubmithandler={handleBlackListSubmit(handleSearchBlackList)}
                >
                  <label
                    for="default-search"
                    className="mb-2 w-full text-sm font-medium text-gray-900 sr-only dark:text-white"
                  >
                    Search
                  </label>
                  <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        class="w-4 h-4 text-gray-500 dark:text-gray-400"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </div>
                    <input
                      type="search"
                      id="blackListSearch"
                      onKeyDown={handleBackList}
                      {...blackRegister("blackListSearch", { required: true })}
                      className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-[#F1F1F1] focus:ring-blue-500 focus:border-blue-500 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Search Blacklist organization..."
                    />
                  </div>
                  <div className="mt-3">
                    {/* {blackLoading} */}
                    {blackListResult.map((item) => (
                      <div className="flex justify-between items-center shadow-md p-3 gap-2">
                        <div className="flex gap-2 items-center">
                          <div className="bg-red/25 rounded-md p-3">
                            <BiBuilding size={22} />{" "}
                          </div>
                          <div className="flex flex-col">
                            <span className="text-sm">{item?.name}</span>
                            <small className="text-md">Agriculture</small>
                          </div>
                        </div>
                        <div>
                          <button className="border-2 rounded-md bg-red px-3 py-1">
                            {/* Compare */}
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </FormProvider>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-sm p-4 rounded-md h-30 space-y-4">
            <div
              className={`p-4 rounded-md w-full h-full border-2 bg-gradient-to-br relative flex items-start gap-4 justify-between ${
                organizationData?.document_status === "Pending"
                  ? "from-yellow20 to-yellow40"
                  : organizationData?.document_status === "Incomplete document"
                  ? "from-orange-200 to-orange-400"
                  : organizationData?.document_status === "Approved"
                  ? "from-green20 to-green40"
                  : organizationData?.document_status === "Rejected"
                  ? "from-red20 to-red40"
                  : ""
              }`}
            >
              <div>
                <div className="flex items-start justify-between h-max flex-col gap-1">
                  <span className="capitalize text-white font-medium">
                    Document Status
                  </span>
                  <div
                    className={`w-fit py-1 px-2 rounded-full capitalize flex items-center justify-center gap-2 text-white ${
                      organizationData?.document_status ===
                        "Incomplete document" && "bg-white/20"
                    } ${
                      organizationData?.document_status === "Pending" &&
                      "bg-white/50"
                    }`}
                  >
                    {organizationData?.document_status === "Pending" && (
                      <MdAccessTime className="w-6 h-6" />
                    )}
                    {organizationData?.document_status ===
                      "Incomplete document" && (
                      <MdErrorOutline className="w-6 h-6" />
                    )}
                    {organizationData?.document_status === "Approved" && (
                      <MdCheckCircle className="w-6 h-6" />
                    )}
                    {organizationData?.document_status === "Rejected" && (
                      <MdCancel className="w-6 h-6" />
                    )}
                    <h3>{organizationData?.document_status}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {organizationData?.assigned_organization_reviewers &&
            organizationData?.assigned_organization_reviewers?.map((i) => (
              <div className="bg-white shadow-sm p-4 rounded-md h-auto space-y-4">
                <span className="font-semibold">Organization Assigned To</span>
                <div className="flex text-sm mx-auto items-center gap-4 justify-between mb-3">
                  <div className="flex items-center gap-2 font-medium text-N0">
                    <BsUpcScan />
                    <p>"Document Checker</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <p>{i?.employee?.user_info?.name}</p>
                  </div>
                </div>
              </div>
            ))}
          <div className="bg-white shadow-sm p-4 rounded-md h-48 space-y-4">
            <StatusCard
              user={organizationData?.assigned_organization_reviewers}
              status={
                organizationData?.review_status === true
                  ? "verified"
                  : organizationData?.review_status === false
                  ? "rejected"
                  : "pending"
              }
            />
          </div>
          <div className="bg-white shadow-sm p-4 rounded-md space-y-4">
            <h2 className="text-primary capitalize font-medium text-sm">
              Organization documents
            </h2>
            {organizationData?.organization_documents?.map((item) => (
              <WorkPermitCard
                title={item?.files?.title}
                note={item?.review_note}
                path={item?.files?.path}
                status={
                  item?.review_status === true
                    ? "Verified"
                    : item?.review_status === false
                    ? "Rejected"
                    : "Pending"
                }
              />
            ))}
          </div>{" "}
        </div>
      </div>

      {/* Drawer Document Viewer */}
      {openDrawer && (
        <DocumentViewerDrawer
          handleVerify={handleOrgDocumentVerify}
          handleRejection={() => setShowDocumentModal(true)}
          selectedDocument={{
            ...selectedDocument,
            review_status: selectedDocument?.review_status,
            review_note: selectedDocument?.review_note,
            files: selectedDocument?.files?.path,
            document_type: {
              name_json: {
                en: selectedDocument?.files?.title,
              },
            },
          }}
          handleCloseDrawer={() => setOpenDrawer(false)}
        />
      )}

      {showModal && (
        <RejectModal
          title={"Reject Organization"}
          handleRejectDocument={handleReject}
          setShowDocumentModal={setShowModal}
        />
      )}

      {showDocumentModal && (
        <RejectModal
          title={"Reject Organization Document"}
          handleRejectDocument={handleRejectDocument}
          setShowDocumentModal={setShowDocumentModal}
        />
      )}
    </>
  );
};

export default Organization;
