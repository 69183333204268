import React, { useState } from "react";
import { WorkPermitForms } from "../../utils/constants";
import GeneralForm from "../Forms/Application/GeneralForm";
import EmploymentInformation from "../Forms/Application/EmploymentInformationForm";
import EducationalInformation from "../Forms/Application/EducationalInformationForm";
import WorkExperience from "../Forms/Application/WorkExperienceForm";
import WorkPlace from "../Forms/Application/WorkPlaceForm";
import CounterPart from "../Forms/Application/CounterPartForm";
import Finance from "../Forms/Application/FinanceForm";
import Skills from "../Forms/Application/SkillsForm";
import ConfirmSubmit from "../Forms/Application/ConfirmSubmit";
import { convertString } from "../../utils/utilityFunctions";
import RenewalEMRForm from "../Forms/Application/RenewalEMRForm";

const FormArea = ({
  title,
  activeStep,
  initialValues,
  setActiveStep,
  handleSubmit,
  handleClearCache,
}) => {
  console.log("initial values: ", initialValues);

  const handleForm = (name, e, delegation_file_number) => {
    console.log(name, e, delegation_file_number);
    handleSubmit(name, e, delegation_file_number);
  };

  return (
    <>
      <div className="w-full md:w-[100%] pb-10 rounded-xl flex flex-col gap-10 h-full">
        {initialValues && Object.keys(initialValues)?.length > 0 && (
          <div className="mb-4 border rounded-sm bg-white p-4">
            <h2 className="font-semibold text-lg underline mb-4">
              Loaded Data from Previous
            </h2>
            <ul className="list-disc list-inside">
              <li className="mb-2">
                These fields are pre-filled with an already started application
                process. If you want to clean and start over,
                <button
                  onClick={handleClearCache}
                  className="text-blue-500 underline hover:text-blue-700 ml-1"
                >
                  click here
                </button>
                .
              </li>
              <li className="mb-2">
                Please review the pre-filled data carefully to ensure its
                accuracy. If any changes are required, make the necessary
                adjustments before proceeding with the application submission.
              </li>
              <li className="mb-2">
                By clicking the "Start Over" button, all the pre-filled
                information will be cleared, and you can begin the application
                process from scratch. This action cannot be undone.
              </li>
            </ul>
            <button
              type="button"
              onClick={handleClearCache}
              className="border-2 border-primary text-primary rounded-md px-4 py-1 mt-4 text-sm hover:shadow-md hover:bg-blue-200"
            >
              Start Over
            </button>
          </div>
        )}

        <div className="bg-white w-full px-6 h-full overflow-hidden noScrollBar">
          <h1 className="text-md select-none font-bold ml-4 border-2 rounded-2xl border-primary w-fit px-4 mt-5 font-['Open_Sans'] text-primary">
            {title}
          </h1>
          {activeStep === WorkPermitForms.GENERAL_INFORMATION && (
            <GeneralForm
              handleFormSubmit={handleForm}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter((i) => i === "general_information")
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.EMPLOYEE_INFORMATION && (
            <EmploymentInformation
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter(
                      (i) =>
                        i ===
                        convertString(WorkPermitForms.EMPLOYEE_INFORMATION)
                    )
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.EDUCATIONAL_INFORMATION && (
            <EducationalInformation
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter(
                      (i) =>
                        i ===
                        convertString(WorkPermitForms.EDUCATIONAL_INFORMATION)
                    )
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.WORK_EXPREIENCE && (
            <WorkExperience
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter(
                      (i) =>
                        i === convertString(WorkPermitForms.WORK_EXPREIENCE)
                    )
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.WORK_PLACE && (
            <WorkPlace
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter(
                      (i) => i === convertString(WorkPermitForms.WORK_PLACE)
                    )
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.COUNTER_PART && (
            <CounterPart
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter(
                      (i) => i === convertString(WorkPermitForms.COUNTER_PART)
                    )
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.FINANCE && (
            <Finance
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter((i) => i === convertString(WorkPermitForms.FINANCE))
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.SKILLS && (
            <Skills
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              defaultValues={
                initialValues &&
                initialValues[
                  Object.keys(initialValues)
                    .filter((i) => i === convertString(WorkPermitForms.SKILLS))
                    .map((i) => i)
                ]
              }
            />
          )}
          {activeStep === WorkPermitForms.FINAL && (
            <ConfirmSubmit
              handleFormSubmit={handleForm}
              changeState={setActiveStep}
              // defaultValues={
              //   initialValues &&
              //   initialValues[
              //     Object.keys(initialValues)
              //       .filter((i) => i === WorkPermitForms.SKILLS)
              //       .map((i) => i)
              //   ]
              // }
            />
          )}

          {/* {activeStep === WorkPermitForms.FINAL && <ConfirmSubmit />} */}
        </div>
      </div>
    </>
  );
};

export default FormArea;
