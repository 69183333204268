export const getImageUrl =  async(filePath) => {
    const primaryUrl = `https://storagex-api.stg.lmis.gov.et/ewp-storage/DEFAULT/${filePath}`;
    const fallbackUrl = `https://storagex-api.stg.lmis.gov.et/ewp-storage/DEFAULT/${filePath}`;
     console.log(filePath,"filePath++++++++++++++++++++++++++++")
    try {
      const response = await fetch(primaryUrl, { method: "HEAD" });
      console.log( response,"responceeeeeeeeeeeeeeeeeeeeeeee");

      // Check if the primary URL is OK
      if (response.ok) {
        return primaryUrl;  // Return primary URL if it exists
      } else {
        console.log("Primary URL failed, returning fallback URL.",fallbackUrl);
        return fallbackUrl;  // Return fallback URL if primary URL is not accessible
      }
    } catch (error) {
      // Log any error and fall back to secondary URL
      console.log("Error fetching primary URL, returning fallback URL:", error);
      return fallbackUrl;
    }
};
