import React, { useRef, useState, useEffect } from "react";
import Barcode from "react-jsbarcode";
import QRCode from "react-qr-code";
import { BsFillTelephonePlusFill } from "react-icons/bs";
import { TfiWorld } from "react-icons/tfi";
import { MdOutlineAlternateEmail, MdPrint } from "react-icons/md";
import { FaSquareFacebook } from "react-icons/fa6";

import { useReactToPrint } from "react-to-print";
import toast from "react-hot-toast";
import { useLazyQuery } from "@apollo/client";
import { trackPromise } from "react-promise-tracker";
import {
  GET_EMR_EXPATRIATE_BY_ID,
  GET_ORG_EXPATRIATE_BY_ID,
  GET_WORK_PERMIT_EMR_FOR_ID,
} from "../../graph-query/queries";
import "./style.css";
import {
  IDCoverImage,
  LMISLogo as LMISIcon,
  molsLogoTransparent,
  IDBackCover,
  signature,
  stamp,
} from "../../utils/images";
import Moment from "moment";
import {
  convertToEthiopian,
  formatDate,
  gregorianToEthiopian,
} from "../utilities/dateConverter";
import { getImageUrl } from "../common/imageUtils";
import { GetLatestProfilePic } from "../../utils/utilityFunctions";
const RequestedApplicationCard = ({ data }) => {
  const [newData, setNewData] = useState(null);
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${
      data?.expatriate?.first_name
    }'s Permit-ID-Card-${new Date().toLocaleDateString()}`,
    onPrintError: () => toast.error("An error occurred while printing."),
  });

  const [getData] = useLazyQuery(GET_EMR_EXPATRIATE_BY_ID, {
    variables: {
      id: localStorage.getItem("expat_id"),
    },
    onCompleted: (fetchedData) => {
      setNewData(fetchedData?.expatriate_work_permits[0]);
    },
    onError: (err) => {
      toast.error("Failed to fetch data!");
      console.error(err);
    },
  });

  useEffect(() => {
    trackPromise(getData());
  }, [getData]);

  const convertDate = (date) => {
    return Moment(date).format("DD/MM/YYYY");
  };
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const newPermit = newData?.expatriate?.expatriate_work_permits.find(
      (permit) =>
        permit.expatriate_work_permit_type?.name.toLowerCase() === "new"
    );
    const fetchImageUrl = async () => {
      if (GetLatestProfilePic(newPermit)) {
        const url = await getImageUrl(GetLatestProfilePic(newPermit));

        setImageUrl(url);
      }
    };

    fetchImageUrl();
  }, [newData?.expatriate?.expatriate_work_permits]);

  // Default to an empty string if undefined
  const currentDate = new Date();

  const day = String(currentDate.getDate()).padStart(2, "0"); // Day with leading zero
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-based
  const year = currentDate.getFullYear(); // Full year

  const formattedDate = `${month}/${day}/${year}`;
  return (
    <div className="mb-12 bg-lightBlue/10 p-6 rounded-md">
      <button
        onClick={handlePrint}
        className="border px-4 py-1.5 rounded-md bg-meta-3 text-black flex gap-2 items-center"
      >
        Print ID <MdPrint />
      </button>

      <div ref={printRef} className="mt-3">
        {/* Front Side of the Card */}
        <div
          id="front"
          className="relative gap-4  rounded-[20px] items-center overflow-hidden  w-[10in] min-h-[5.2in] max-h-[7.2in] mx-auto p-0 border mt-[-2%] border-white bg-white bg-clip-border shadow-2xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:bg-boxdark dark:!shadow-none"
        >
          <div className="p-0 bg-primary relative h-[130px] overflow-hidden flex w-full justify-center bg-cover">
            <img
              src={IDCoverImage}
              className="absolute flex h-48 w-full justify-center rounded bg-cover"
            />
            <div className="w-full px-2 p-6 flex justify-between">
              <img
                className="w-fit h-22 ml-12"
                src={molsLogoTransparent}
                alt="mols logo"
              />
              <h1 className="mr-12 self-center text-2xl uppercase text-white font-bold">
                work permit id
              </h1>
            </div>
          </div>
          <div className="border-t-8 border-black/80 relative grid grid-cols-2 gap-6 ">
            <div>
              <div className="w-[227px]   flex flex-col ml-7   items-center h-full">
                <div className="w-[180px] -mt-7  ml-14   rounded-lg ring-2 border border-black/15 ring-white ">
                  <img className="object-cover" src={imageUrl} alt="" />
                </div>
                <div className=" w-[170%] ml-20 text-center text-primary font-bold">
                  <h3 className="text-[25px] ">
                    {/* {" አለማየሁ እሸቴ አዳነ"} <br/> {"Alemayehu Eshete Adane"} */}
                    {newData?.expatriate?.first_name} {""}
                    {newData?.expatriate?.father_name}
                  </h3>
                  {/* <h3>ABDURRAHMAN ABDULMUMIN MOHAMMED</h3> */}

                  <span className="text-meta-1 text-[#F26769] text-[25px]">
                    {newData?.emr_number}

                    {/* {data?.emr_number} */}
                  </span>
                </div>
                <div className="bg-[#E8F2FE] ml-9  text-[20px] dark:bg-boxdark-2 w-full rounded-2xl p-3 mt-5 text-center text-black ">
                  <h3>Nationality / ዜግነት</h3>
                  <h2 className="uppercase font-bold">
                    {newData?.expatriate?.nationality?.name}
                  </h2>
                </div>
              </div>
            </div>
            <div className="p-5 relative flex flex-col gap-4">
              <img
                src={IDBackCover}
                className="absolute left-0 right-0 z-9"
                alt=""
              />
              <div className="z-10">
                <span className="text-black text-[25px]">
                  Organization / የድርጅት ስም
                </span>
                <h4 className="text-primary text-[25px] text-md font-semibold">
                  {newData?.organization?.name}
                </h4>
              </div>
              <div className="z-10">
                <span className="text-black text-[25px]">
                  Position / የስራ ዘርፍ
                </span>
                <h4 className="text-primary font-semibold text-[25px]">
                  {localStorage.getItem("job")}
                  {/* {
                                newData?.profession_title?.name_json[
                                  "en"
                                ]
                              } */}
                </h4>
              </div>
              <div className="flex gap-10 z-10">
                <div>
                  <span className="text-black text-[25px]">Issued Date</span>
                  <h4 className="text-primary font-semibold text-[25px]">
                    የተሰጠበት ቀን
                  </h4>
                </div>
                <div>
                  <span className=" text-black text-[25px] ml-2">
                    {formatDate(convertDate(formattedDate))}
                  </span>
                  <h4 className="text-primary font-semibold text-[25px] ml-2 ">
                    {convertToEthiopian(formatDate(convertDate(formattedDate)))}
                    {/* {gregorianToEthiopian("12/01/2024")} */}
                  </h4>
                </div>
              </div>
              <div className="flex gap-4 z-10">
                <div className="mt-3">
                  <span className=" text-black text-[25px]  ">Expiry Date</span>
                  <h4 className="text-primary font-semibold text-[25px]">
                    የሚያበቃበት ቀን
                  </h4>
                </div>
                <div>
                  <span className=" text-black text-[25px]  ">
                    {formatDate(
                      convertDate(localStorage.getItem("expiryDate"))
                    )}
                    {/* {convertDate(data?.expiry_date)} */}
                  </span>
                  <h4 className="text-primary font-semibold text-[25px]">
                    {convertToEthiopian(
                      formatDate(
                        convertDate(localStorage.getItem("expiryDate"))
                      )
                    )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Back Side of the Card */}
        <div className="mt-6" id="back">
          <div className="relative grid grid-rows-2 gap-4 items-start overflow-hidden rounded-[20px] w-[10in] min-h-[6.2in]  max-h-[7.2in]  mx-auto p-0 border border-white bg-white bg-clip-border shadow-2xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:bg-boxdark dark:!shadow-none">
            <div className="p-5 bg-white relative h-full overflow-hidden flex w-full justify-between bg-cover">
              <img
                src={IDBackCover}
                alt="mold"
                className="absolute top-0 left-0 right-0 z-10"
              />
              <div className="pl-10 z-20">
                <span className="text-lg text-primary font-bold">
                  Powered by
                </span>
                <img src={LMISIcon} alt="" />
              </div>
              <div className="text-center pr-10">
                <div className="w-[134px] h-[134px] rounded-md">
                  <QRCode
                    size={256}
                    fgColor="#3170B5"
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                      fill: "#3170B5",
                      borderRadius: "10px",
                    }}
                    value={`${newData?.id}`}
                    viewBox={`0 0 256 256`}
                  />
                  <span className="font-bold text-sm text-center text-black">
                    የውጭ ሀገር ሰራተኛ መረጃውን ለማጣራት ይህንን በካሜራ እሰካን ያድርጉ
                  </span>
                </div>
              </div>
            </div>
            <div className="border-t-0 border-black/80 relative">
              <div className="h-[100px] bg-primary text-lg text-white w-full items-center place-items-center grid grid-cols-2 gap-6">
                <div className="flex flex-col gap-2">
                  <span className="flex items-center gap-2">
                    <BsFillTelephonePlusFill /> +2511 6 29 16 06
                  </span>
                  <span className="flex items-center gap-2">
                    <MdOutlineAlternateEmail /> mols.ethiopia@gmail.com
                  </span>
                </div>
                <div className="flex flex-col gap-2">
                  <span className="flex items-center gap-2">
                    <TfiWorld /> www.mols.gov.et
                  </span>
                  <span className="flex items-center gap-2">
                    <FaSquareFacebook /> fb.com / MolsFDRE
                  </span>
                </div>
              </div>
              <div className="flex  ">
                <div className="">
                  <Barcode
                    value={"generated_ID?.id_number"}
                    options={{ height: 50, width: 1, displayValue: false }}
                  />
                </div>
                <div>
                  <div className="flex flex-col text-black font-semibold  ml-11 ">
                    <span className="text-[25px]">Authorized signature</span>
                    <span className="text-[25px]">የባለስልጣኑ ፊርማ</span>
                  </div>
                </div>
                <img
                  src={signature}
                  alt=""
                  className="w-[150px] h-[150px] object-cover  ml-11 "
                />
              </div>
            </div>

            <div className="absolute top-[20%] left-[40%]">
              <img src={stamp} className="w-[300px] h-[300px]" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestedApplicationCard;

/* Add this CSS to your styles */

/* Printing Styles */
