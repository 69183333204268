import { toast } from "react-toastify";
import numeral from "numeral";
import { PROFILE_IMAGE_ID, PROFILE_IMAGE_ID2 } from "./constants";

const DOCUMENT_TYPES = [
  "Application Letter",
  "Letter of Delegation",
  "Support Letter",
  "Employment Contract",
  "Educational Certificate",
  "Bank Slip",
  "Profile Picture",
  "Visa",
  "Passport",
];
/**
 * @description Convert data to base64
 * @param {*} file
 * @returns
 */
export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result.split(",")[1];
      resolve(baseURL);
    };
  });
};

/**
 * @description decode base64 to readable string
 * @param {*} base64String
 * @returns
 */
export const base64ToData = (base64String) => {
  const byteCharacters = atob(base64String);
  const byteArray = new Uint8Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArray[i] = byteCharacters.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);

  return url;
};

/**
 * @description decode JWT token
 * @param {*} token
 * @returns
 */
export const decodeJWT = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const phoneValidation = (phone) => {
  // remove all non-numeric characters from the input
  let digitsOnly = phone.replace(/\D/g, "");

  // check if the input starts with "09" (for the first phone number)
  if (digitsOnly.startsWith("09")) {
    // remove the first two digits (09) and replace them with "251"
    digitsOnly = "251" + digitsOnly.substring(1);
  }
  if (digitsOnly.startsWith("251")) {
    // remove the first two digits (09) and replace them with "251"
    digitsOnly = digitsOnly;
  }
  if (digitsOnly.startsWith("+251")) {
    // remove the first two digits (09) and replace them with "251"
    digitsOnly = digitsOnly.substring(1);
  }

  // remove any leading zeroes from the phone number
  digitsOnly = digitsOnly.replace(/^0+/, "");

  return digitsOnly;
};

/**
 * @description custom error handling
 * @param {*} error
 * @returns
 */
export const handleErrorFunction = (error) => {
  console.log("_______", error.extensions);
  console.log("_______", error.message);
  if (
    error.extensions &&
    error.extensions.code &&
    error.extensions.code === "permission-error"
  ) {
    if (
      error.extensions.path ===
        "$.selectionSet.insert_organizations_one.args.object" &&
      error.message.includes('violates check constraint "woreda_district"')
    ) {
      // Handle the check constraint violation error for the "woreda_district" constraint
      // For example, show an error message to the user
      console.log(
        "The new organization violates the 'woreda_district' constraint."
      );
      return "Check Your Form again woreda/district";
    } else {
      // Handle other permission errors if needed
      // For example, show a generic error message to the user
      return "There was a permission error while inserting the new organization.";
    }
  } else {
    // Handle other errors if needed
    // For example, log the error to the console for debugging purposes
    console.error(error);
    return "An error occurred while inserting the new organization.";
  }
};

export const handleOrganizationError = (error) => {
  if (
    error.message.includes(
      'Check constraint violation. new row for relation "organizations" violates check constraint "woreda_district"'
    )
  ) {
    // Handle the check constraint violation error for the "woreda_district" constraint
    return "Please Select Woreda!.";
  } else if (
    error.message.includes(
      'Uniqueness violation. duplicate key value violates unique constraint "organizations_tin_number_key"'
    )
  ) {
    return "Sorry, the Tin Number you entered is already in use.";
  } else if (
    error.error.includes(
      `"Uniqueness violation. duplicate key value violates unique constraint "users_phone_number_key"`
    )
  ) {
    return "Sorry, the phone number you entered is already in use";
  } else {
    // Handle other errors if needed
    return "An error occurred while inserting the new organization.";
  }
};

/**
 * @description arrange document by document_type id and filter(organizae multiple duplicate documents using one id)
 * @param {*} arr
 */
export const arragePermitDocumentCatagory = (arr) => {
  console.log(arr);
  return arr?.reduce((result, item) => {
    const documentTypeId = item?.document_type?.id;
    if (!result[documentTypeId]) {
      result[documentTypeId] = [];
    }
    result[documentTypeId].push(item);
    return result;
  }, {});
};

/**
 * @description filter document against document category and assemble together for organization purpose
 * @param {*} documents
 * @param {*} categories
 * @returns
 */
export const filterAndGroupByDocumentTypeAndCategory = (
  documents,
  categories
) => {
  return documents?.reduce((result, item) => {
   console.log(item?.document_type?.document_type_category?.id,"itemitem")
    const documentTypeId = item?.properties?.catagory;
    const documentCategory = categories.find(
      (category) => category.id === item?.document_type?.document_type_category?.id
    );
    console.log(documentCategory)
    // console.log("filter", result, item);
    if (!result[documentCategory?.code]) {
      result[documentCategory?.code] = {
        documents: [],
        category: documentCategory ? documentCategory.code : null,
      };
    }

    result[documentCategory?.code].documents.push(item);
    console.log(result)
    return result;
  }, {});
};

// Function to check if every latest document category is verified or not
export const checkLatestDocumentCategoriesVerification = (
  documentInformation
) => {
  const latestDocumentsByCategory = {};

  for (const category in documentInformation) {
    const { documents } = documentInformation[category];
    const latestDocument = getLatestVerifiedDocument(documents);

    if (latestDocument) {
      latestDocumentsByCategory[category] = latestDocument;
    }
  }

  for (const category in latestDocumentsByCategory) {
    const latestDocument = latestDocumentsByCategory[category];
    if (latestDocument.review_status === true) {
      return true;
    }
  }

  return false;
};

// Helper function to get the latest verified document
function getLatestVerifiedDocument(documents) {
  let latestVerifiedDocument = null;

  let sortDocument = documents.sort((a, b) => a.created_at - b.created_at);
  console.log(sortDocument);
  for (const document of sortDocument) {
    if (document.review_status === true) {
      if (
        !latestVerifiedDocument ||
        document.created_at > latestVerifiedDocument.created_at
      ) {
        latestVerifiedDocument = document;
      }
    }
  }

  return latestVerifiedDocument;
}

/**
 *
 * @param {*} arr
 * @returns
 * @access Sys Admin, Desk Manager
 */
export const OrganizaEmployeeByRole = (arr) => {
  // Organize employees by role value
  return arr?.reduce((acc, employee) => {
    const roleValue =
      employee.user_info.allowed_roles[0]?.role_information.value;

    if (roleValue) {
      const existingEmployees = acc[roleValue] || [];
      acc[roleValue] = [...existingEmployees, employee];
    }

    return acc;
  }, {});
};

export const GetLatestProfilePic = (documents) => {
  const profileImages = documents?.expatriate_work_permit_documents
  ?.filter((i) => ((i?.document_type?.id === (PROFILE_IMAGE_ID))||( i?.document_type?.id === (PROFILE_IMAGE_ID2))))
    ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));
  const latestVerifiedImage = profileImages?.find(
    (image) => image?.review_status === true
  );
  if (latestVerifiedImage !== undefined) {
    return latestVerifiedImage.files;
  }
  const latestNonVerifiedImage = profileImages?.find(
    (image) => image?.review_status === null || image?.review_status === false
  );
  if (latestNonVerifiedImage) {
    return latestNonVerifiedImage.files;
  }

  return null;
};

/**
 * @description decode base64 string
 * @param {*} base64String
 * @returns
 */
export const ExtractFileTypeAndNameFromBase64 = (
  base64String,
  defaultFileName = "file",
  defaultFileType = "application/pdf"
) => {
  if (base64String) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(
        offset,
        offset + Math.min(512, byteCharacters.length - offset)
      );

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: defaultFileType });
    const file = new File([blob], defaultFileName, { type: defaultFileType });
    return file;
  }
};

//function that check if all document is verified or not
export const isDocumentVerified = (title, documents) => {
  const matchingDocuments = documents.filter((doc) => doc.title === title);
  if (matchingDocuments.length > 0) {
    // Check if any document has review_status = true
    return matchingDocuments.some((doc) => doc.review_status === true);
  }
  return false; // Document not found
};

/**
 * @description convert string to lowercase
 * @param {*} str
 * @returns
 */
export const convertString = (str) => {
  // Convert the string to lowercase
  const lowercasedStr = str?.toLowerCase();

  // Replace spaces with underscore
  const modifiedStr = lowercasedStr?.replace(/ /g, "_");

  return modifiedStr;
};

/**
 * @description convert indexDB key string like "general_information" => "General Information"
 * @param {*} key
 * @returns
 */
export const convertKeyFormat = (key) => {
  // Split the key by underscores
  const words = key.split("_");

  // Capitalize the first letter of each word and join them with a space
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toLowerCase() + word.slice(1)
  );

  // Join the words with a space to form the modified key
  const modifiedKey = capitalizedWords.join(" ");

  return modifiedKey;
};

/**
 * @description convert Thu Nov 16 2000 00:00:00 GMT+0300 (East Africa Time)  to input date
 * @param {*} inputDate
 * @returns
 */
export const formatDate = (inputDate) => {
  const date = new Date(inputDate);

  // Get the individual components of the date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // Format the date as YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

// file upload
export const fData = (number) => {
  const format = number ? numeral(number).format("0.0 b") : "";

  return result(format, ".0");
};

function result(format, key = ".00") {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, "") : format;
}

export const checkArrayObjects = (documents) => {
  const duplicates = new Map();
  let allResolved = true;
  const updatedFiles = [];
  console.log(documents);

  try {
    for (const file of documents) {
      if (!file.review_status) {
        allResolved = false;
      }
      // if (file.review_status === true) {
      //   allResolved = true;
      // }

      const key = file?.document_type?.id;
      if (duplicates.has(key)) {
        const existingFile = duplicates.get(key);
        if (file.created_at > existingFile.created_at) {
          // console.log("__", file);
          // console.log("__", existingFile);
          existingFile.review_status = file?.review_status;
        } else {
          existingFile.review_status =
            existingFile?.review_status || file?.review_status;
        }
        continue;
      } else {
        duplicates.set(key, {
          created_at: file.created_at,
          review_status: file.review_status,
        });
      }

      if (file.review_status === true || file.review_status !== null) {
        const updatedFile = {
          id: file.id,
          title: file.title,
          document: file?.document_type?.name_json?.en,
          review_status: file?.review_status,
        };
        updatedFiles.push(updatedFile);
      } else {
        updatedFiles.push({
          id: file.id,
          title: file.title,
          document: file?.document_type?.name_json?.en,
          review_status: file?.review_status,
        });
      }
    }
    // Check if all documents are resolved
    // if (!allResolved) {
    // for (const file of documents) {
    //   if (file.review_status === true) {
    //     allResolved = true;
    //     break;
    //   }else{
    //     allResolved = false
    //   }
    // }
    // }

    // Add non-duplicated files to updatedFiles
    for (const file of documents) {
      const key = file?.document_type?.id;
      if (!duplicates.has(key)) {
        console.log("non-duplication", key);
        updatedFiles.push({
          id: file.id,
          title: file.title,
          document: file?.document_type?.name,
          review_status: file.review_status,
        });
      }
    }

    return { updatedFiles, allResolved };
  } catch (error) {
    console.log(error);
  }
};

export const processDocuments = (documents) => {
  console.log(documents)
  const groupedDocuments = {};

  if (documents?.length > 0) {
    documents.forEach((document) => {
      const documentType = document?.document_type?.name_json["en"];

      if (!groupedDocuments[documentType]) {
        groupedDocuments[documentType] = [];
      }

      groupedDocuments[documentType].push(document);
    });

    let overallStatus = true;
   
    for (const documentType in groupedDocuments) {
      const documents = groupedDocuments[documentType];

      let typeStatus = false;

      for (const document of documents) {
        const reviewStatus = document.review_status;
        if (reviewStatus === true) {
          typeStatus = true;
          break;
        } else if (reviewStatus === false) {
          typeStatus = false;
        }
      }
      if (typeStatus === false) {
        overallStatus = false;
        break;
      }
    }
    return overallStatus;
  }
};

export const checkReviewStatus = (array) => {
  for (const item of array) {
    if (item.review_status !== true) {
      return false; // If any object has review_status not set to true, return false
    }
  }

  return true; // If all objects have review_status set to true, return true
};
