import React, { useRef } from "react";
import FileInput from "../../../components/Forms/Fields/FileInput";
import {
  CInputField,
  CSelectField,
  FormProvider,
} from "../../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  BASE_DOCUMENT_CATAGORY_TYPES,
  BASE_DOCUMENT_TYPES,
} from "../../../graph-query/queries";
import { useLocalStore } from "../../../store";
import { useEffect } from "react";
import { useState } from "react";
import CButton from "../../../components/Forms/CButton";
import {
  INSERT_EXPATRIATE_DOC,
  UPLOAD_FILE,
} from "../../../graph-query/mutation";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { getBase64 } from "../../../utils/utilityFunctions";

const FIleUploadPage = () => {
  const [documentTYpe, setDocumentTYpe] = useState([]);
  const [documentCatagory, setDocumentCatagory] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [tempFiles, setTempFile] = useState([]);

  const [fileType, setFileType] = useState(false);
  const [fileLink, setFileLink] = useState(null);

  const inputRef = useRef();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);

  const filesUpload = (uploadedFile, cb) => {
    getBase64(uploadedFile)
      .then((result) => {
        let fileType = (uploadedFile?.type).split("/").length
          ? (uploadedFile?.type).split("/")[1]
          : "";
        // upload the file to the server
        trackPromise(
          fileUpload({
            variables: {
              extension: fileType,
              file: result,
            },
            onCompleted(data) {
              // console.log(data);
              cb &&
                cb({ name: uploadedFile.name, path: data.uploadFile.info.Key });
              // toast.success("Document Uploaded Successfully!");
            },
            onError(error) {
              // toast.error("Document upload failed!");
            },
          })
        );
      })
      .catch((err) => {
        // handle error
      });
  };

  //   const { documentTypes } = useLocalStore();
  const handleFileChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        //   setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
      filesUpload(file, (filePath) =>
        setTempFile((prevFiles) => [
          ...prevFiles,
          {
            ...filePath,
          },
        ])
      );
    }
  };
  const [getDocument, {}] = useLazyQuery(BASE_DOCUMENT_TYPES, {
    onCompleted: (d) => {
      setDocumentTYpe(d?.base_document_types);
    },
  });

  const [getDocumentCatagory, {}] = useLazyQuery(BASE_DOCUMENT_CATAGORY_TYPES, {
    onCompleted: (d) => {
      setDocumentCatagory(d?.base_document_type_categories);
    },
  });

  const [createDocument, { loading }] = useMutation(INSERT_EXPATRIATE_DOC, {
    onCompleted: () => {
      toast.success("Document Insert Successfully ");
      setTempFile([]);
      reset();
    },
  });

  useEffect(() => {
    getDocument();
    getDocumentCatagory();
  }, []);

  const getTitle = (id) => {
    return documentTYpe.filter((t) => t.id === id).map((j) => j.name)[0];
  };

  function handleToggle() {
    setIsChecked(!isChecked);
  }

  /**
   * @description handle File Upload and form for specific work permit using ID
   * @param {*} e
   */
  const handleFileSubmit = (e) => {
    const _prop = {
      name: tempFiles[0]?.name,
      path: tempFiles[0].path,
      title: getTitle(e?.document_type_id),
      document_type_id: e.document_type_id,
      catagory: e.catagory,
      ...(getTitle(e?.document_type_id) === "Letter of Delegation" && {
        delegation_file_number: e.delegation_file_number,
      }),
    };
    // console.log("submit", _prop);
    createDocument({
      variables: {
        documentId: e.document_type_id,
        expatriate_work_permit_id: e.expatriate_work_permit_id,
        files: `${tempFiles[0].path}`,
        properties: _prop,
        title: getTitle(e?.document_type_id),
        ...(getTitle(e?.document_type_id) === "Letter of Delegation" && {
          delegation_file_number: e.delegation_file_number,
        }),
      },
    });
  };

  const handleDropFile = (_dropFile) => {
    if (_dropFile) {
      const reader = new FileReader();
      reader.onloadend = () => {};
      reader.readAsDataURL(_dropFile);
      filesUpload(_dropFile, (filePath) => {
        console.log(filePath);
        setFileLink(filePath);
      });
    }
  };

  const handleCopyToClipBoard = () => {
    console.log("copy to clipboard");
    // Create a temporary textarea element
    const textarea = document.createElement("textarea");
    textarea.value = fileLink?.path;

    // Append the textarea element to the DOM
    document.body.appendChild(textarea);

    // Select the text inside the textarea
    textarea.select();

    // Copy the selected text to the clipboard
    document.execCommand("copy");

    // Remove the temporary textarea from the DOM
    document.body.removeChild(textarea);
  };

  return (
    <div className="w-full">
      <h1 className="text-2xl font-bold p-4">File Upload</h1>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <FormProvider onSubmithandler={handleSubmit(handleFileSubmit)}>
            <div className="bg-white flex flex-col p-8 gap-3">
              <CInputField
                name="expatriate_work_permit_id"
                register={register}
                errors={errors}
                type="text"
                label="Expatriate Work Permit ID"
                placeholder=""
              />
              <CSelectField
                name="catagory"
                register={register}
                errors={errors}
                validation={{ required: "Document Catagory is required" }}
                options={documentCatagory?.map((reg) => {
                  return {
                    ...reg,
                    value: reg?.id,
                    name: reg?.code,
                  };
                })}
                label="Document Catagory"
                placeholder="Select Catagroy Type"
              />
              <CSelectField
                name="document_type_id"
                register={register}
                errors={errors}
                validation={{ required: "Document Type is required" }}
                options={documentTYpe?.map((reg) => {
                  return {
                    ...reg,
                    value: reg?.id,
                    name: reg?.name,
                  };
                })}
                label="Document Catagory"
                placeholder="Select Catagroy Type"
              />

              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={isChecked}
                  onChange={handleToggle}
                />
                <div
                  className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}
                ></div>
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Image File
                </span>
              </label>

              <FileInput
                type={isChecked ? "image" : "file"}
                onChange={handleFileChange}
                enablePreview={true}
              />
              <CButton
                classes={"bg-primary"}
                loading={loading}
                btnLabel={"Submit Document"}
                type={"submit"}
              />
            </div>
          </FormProvider>
        </div>

        <div className="bg-white h-auto p-4">
          <p className="text-start font-bold uppercase">
            {process.env.NODE_ENV}
          </p>
          <FileInput
            onChange={handleDropFile}
            type={"image"}
            enablePreview={true}
          />

          <div className="mt-5">
            <label
              for="search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only"
            >
              File Path
            </label>
            <div className="relative">
              <input
                type="text"
                id="search"
                disabled
                ref={inputRef}
                value={fileLink?.path}
                className="block w-full p-4 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="File Path"
              />
              <button
                type="button"
                onClick={handleCopyToClipBoard}
                className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FIleUploadPage;
