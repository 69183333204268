import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import {
  BASE_DOCUMENT_CATAGORY_TYPES,
  BASE_NATIONALITY,
  GET_WORK_PERMIT_BYID_FOR_ORG,
} from "../../../graph-query/queries";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { MdArrowBack, MdEmail, MdFlag, MdHouse, MdPhone } from "react-icons/md";
import {
  BsCalendar2Date,
  BsCalendarDate,
  BsBuilding,
  BsFillPersonCheckFill,
  BsFillPersonFill,
  BsUpcScan,
} from "react-icons/bs";
import InfoCard from "../../../components/cards/InfoCard";
import { FiAlertCircle } from "react-icons/fi";
import StatusCard from "../../../components/StatusCard";
import WorkPermitCard from "../../../components/cards/WorkPermitCard";
import { useLocalStore } from "../../../store";
import { roles } from "../../../utils/roles";
import { BANK_SLIP_ID, BANK_SLIP_ID1, BANK_SLIP_ID2, BANK_SLIP_ID3, WORK_PERMIT_TYPES } from "../../../utils/constants";
import PermitWarningCard from "../../../components/cards/PermitWarningCard";
import { GetLatestProfilePic } from "../../../utils/utilityFunctions";
import {
  CInputField,
  CSelectField,
  FormProvider,
} from "../../../components/Forms/Fields";
import { useForm } from "react-hook-form";
import CButton from "../../../components/Forms/CButton";
import { trackPromise } from "react-promise-tracker";
import { isEqual } from "lodash";
import CustomDatepicker from "../../../components/Forms/Fields/Datepicker";
import {
  UPDATE_EXPAT_PROFILE,
  UPDATE_PERMIT_PROFILE,
  UPLOAD_FILE,
} from "../../../graph-query/mutation";
import { toast } from "react-toastify";
import Tooltip from "../../../components/Tooltip";
import { getImageUrl } from "../../../components/common/imageUtils";

const DetailOrganization = () => {
  const [applicantData, setApplicantData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [permitDocument, setPermitDocument] = useState([]);
  const [documentCatagory, setDocumentCatagory] = useState([]);
  const [disabledForm, setDisabledForm] = useState(false);
 console.log("tt")
  const [openAccordion, setOpenAccordion] = useState({
    id: null,
    status: false,
  });

  const [tab, setTab] = useState("profile");

  /**
   * @description file upload form state management
   */
  const {
    register: registerUpload,
    watch,
    handleSubmit: handleSubmitUpload,
    formState: { errors: errorsUpload },
  } = useForm();

  const { id } = useParams();
  const router = useNavigate();

  const watchCatagory = watch("catagory", false);
  const selectedCatagory = watch("document_type_id", false);

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm({
    defaultValues: {
      ...applicantData,
      first_name: applicantData?.expatriate?.first_name,
      father_name: applicantData?.expatriate?.father_name,
      date_of_birth: applicantData?.expatriate?.date_of_birth,
      grand_father_name: applicantData?.expatriate?.grand_father_name,
      passport_number: applicantData?.passport_number,
      emr_number: applicantData?.emr_number,
      visa_type: applicantData?.expatriate_work_permit_type,
      nationality_id: applicantData?.expatriate?.nationality?.id,
      visa_type_id: applicantData?.expatriate?.visa_type?.id,
      start_date: applicantData?.start_date,
      end_date: applicantData?.end_date,
    },
  });

  const [fileUpload, { loading: uploadLoading }] = useMutation(UPLOAD_FILE);

  //Get Document categories
  const [getDocumentCatagory, {}] = useLazyQuery(BASE_DOCUMENT_CATAGORY_TYPES, {
    onCompleted: (d) => {
      setDocumentCatagory(d?.base_document_type_categories);
    },
  });

  useEffect(() => {
    getDocumentCatagory();
    // getPermitDocuments();
  }, []);
  const { currentRole, documentTypes, nationality, setNationality } =
    useLocalStore();

  useEffect(() => {
    if (nationality?.length === 0) {
      getNationality();
    }
  }, [nationality, setNationality]);

  const [getNationality, {}] = useLazyQuery(BASE_NATIONALITY, {
    onCompleted: (data) => {
      setNationality(data?.base_nationalities);
    },
  });

  const getTitle = (id) => {
    return documentTypes.filter((t) => t.id === id).map((j) => j.name)[0];
  };

  /**
   * @description get work permit data
   * @access Organization
   */
  const [getData, { error, loading }] = useLazyQuery(
    GET_WORK_PERMIT_BYID_FOR_ORG,
    {
      variables: {
        id: id,
      },
      onCompleted: (data) => {
        // console.log("permit detail: ", data?.expatriate_work_permits[0]);
        setApplicantData(data?.expatriate_work_permits[0]);
        setValue(
          "first_name",
          data?.expatriate_work_permits[0].expatriate.first_name
        );
        setValue(
          "father_name",
          data?.expatriate_work_permits[0].expatriate.father_name
        );
        setValue(
          "grand_father_name",
          data?.expatriate_work_permits[0].expatriate.grand_father_name
        );
        setValue(
          "passport_number",
          data?.expatriate_work_permits[0].expatriate?.passport_number
        );
        setValue("emr_number", data?.expatriate_work_permits[0]?.emr_number);
        setValue("start_date", data?.expatriate_work_permits[0]?.start_date);
        setValue("end_date", data?.expatriate_work_permits[0]?.end_date);
        setValue(
          "date_of_birth",
          data?.expatriate_work_permits[0]?.expatriate?.date_of_birth
        );
        setValue(
          "nationality_id",
          data?.expatriate_work_permits[0]?.expatriate?.nationality?.id
        );
        // setValue("passport_expiry_date", data?.expatriate_work_permits[0]?.passport_expiry_date);
        // setValue("passport_issue_date", data?.expatriate_work_permits[0]?.passport_issue_date);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const [updateExpat, {}] = useMutation(UPDATE_EXPAT_PROFILE);
  const [updatePermit, {}] = useMutation(UPDATE_PERMIT_PROFILE);

  const handleExpatForm = (e) => {
    console.log("expat:", e);
    //check expat profile
    const {
      first_name,
      father_name,
      grand_father_name,
      nationality_id,
      passport_number,
      start_date,
      end_date,
      emr_number,
      date_of_birth,
    } = e;

    //expat profile check
    const _newFormData = {
      first_name: first_name,
      father_name: father_name,
      grand_father_name: grand_father_name,
      passport_number: passport_number,
      nationality_id: nationality_id,
      date_of_birth: date_of_birth,
    };
    const originalDataExpat = {
      first_name: applicantData?.expatriate.first_name,
      father_name: applicantData?.expatriate.father_name,
      grand_father_name: applicantData?.expatriate.grand_father_name,
      passport_number: applicantData?.expatriate.passport_number,
      nationality_id: applicantData?.expatriate?.nationality?.id,
      date_of_birth: applicantData?.expatriate?.date_of_birth,
    };

    // permit check
    const _newPermitData = {
      start_date: start_date,
      end_date: end_date,
      emr_number: emr_number,
    };
    const originalPermitData = {
      start_date: applicantData?.start_date,
      end_date: applicantData?.end_date,
      emr_number: applicantData?.emr_number,
    };

    // Perform data integrity check
    const isFilesEqual = isEqual(_newFormData, originalDataExpat);
    const isFilesEqualPermit = isEqual(_newPermitData, originalPermitData);
    console.log("expat check: ", isFilesEqual);
    console.log("permit check: ", isFilesEqualPermit);

    if (!isFilesEqual) {
      console.log("update expat progress...");
      updateExpat({
        variables: {
          id: applicantData?.expatriate?.id,
          fname: _newFormData.first_name,
          mname: _newFormData.father_name,
          lname:
            _newFormData.grand_father_name === ""
              ? null
              : _newFormData.grand_father_name,
          passport: _newFormData.passport_number,
          date_of_birth: _newFormData.date_of_birth,
          nationality_id: _newFormData.nationality_id,
        },
        onCompleted: () => {
          toast.success("Expatriate Profile updated successfully");
          getData({ fetchPolicy: "network-only" });
        },
        onError: (er) => {
          console.log(er);
        },
      });
    }
    if (!isFilesEqualPermit) {
      console.log("update permit progress...");
      // if (applicantData?.expatriate_work_permit_type === "Renewal") {
      updatePermit({
        variables: {
          // passport_issue_date
          // passport_expiry_date
          // visa_issue_date
          // visa_expiry_date
          id: id,
          start_date: _newPermitData.start_date,
          end_date: _newPermitData.end_date,
          emr_number:
            _newPermitData.emr_number === "" ? null : _newPermitData.emr_number,
        },
        onCompleted: () => {
          toast.success("Expatriate Profile updated successfully");
          getData({ fetchPolicy: "network-only" });
        },
      });
      // }
    }
  };

  const handleFileSubmit = (e) => {
    console.log("clicked; ", e);
    // if (tempFiles.length !== 0) {
    //   if (selectedCatagory === BANK_SLIP_ID) {
    //     if (e.bank_reference_number === "") {
    //       return toast.error("Enter Bank Reference Number");
    //     } else {
    //       const _prop = {
    //         name: tempFiles[0]?.name,
    //         path: tempFiles[0].path,
    //         title: getTitle(e?.document_type_id),
    //         document_type_id: e.document_type_id,
    //         catagory: e.catagory,
    //       };
    //       console.log("submit", _prop);
    //       createDocument({
    //         variables: {
    //           documentId: e.document_type_id,
    //           expatriate_work_permit_id: id,
    //           files: `${tempFiles[0].path}`,
    //           properties: _prop,
    //           title: getTitle(e?.document_type_id),
    //         },
    //       });

    //       updatePermitReference({
    //         variables: {
    //           id: id,
    //           reference: e?.bank_refernce_number,
    //         },
    //       });
    //     }
    //   } else {
    //     const _prop = {
    //       name: tempFiles[0]?.name,
    //       path: tempFiles[0].path,
    //       title: getTitle(e?.document_type_id),
    //       document_type_id: e.document_type_id,
    //       catagory: e.catagory,
    //     };
    //     console.log("submit", _prop);
    //     createDocument({
    //       variables: {
    //         documentId: e.document_type_id,
    //         expatriate_work_permit_id: id,
    //         files: `${tempFiles[0].path}`,
    //         properties: _prop,
    //         title: getTitle(e?.document_type_id),
    //       },
    //     });
    //   }
    // } else {
    //   toast.warn("Please Upload Document and Fill all fields");
    // }
  };

  useEffect(() => {
    trackPromise(getData());
  }, []);

  useEffect(() => {
    if (applicantData) {
      if (applicantData?.review_status) {
        setDisabledForm(true);
      }
    }
  }, [applicantData]);

  const groupDocument = (documents) => {
    const groupedDocuments = {};

    if (documents && documents.length > 0) {
      documents?.forEach((document) => {
        const documentType = document.document_type.name_json["en"];

        if (!groupedDocuments[documentType]) {
          groupedDocuments[documentType] = [];
        }

        groupedDocuments[documentType].push(document);
      });

      const resolvedResults = [];

      for (const documentType in groupedDocuments) {
        const documents = groupedDocuments[documentType];

        const cleanedDocuments = [];

        const seenIds = new Set();
        let latestDocument = null;

        for (let i = documents.length - 1; i >= 0; i--) {
          const document = documents[i];
          const documentId = document.id;

          if (seenIds.has(documentId)) {
            continue;
          }

          seenIds.add(documentId);

          if (!latestDocument && document.review_status === true) {
            latestDocument = document;
          }

          cleanedDocuments.unshift(document);
        }

        const groupOverallStatus = latestDocument
          ? latestDocument.review_status
          : null;

        resolvedResults.push({
          documentType,
          documents: cleanedDocuments,
          overallStatus: groupOverallStatus,
        });
      }

      console.log("====================================");
      console.log(resolvedResults);
      setGroupedData(resolvedResults);
      console.log("====================================");
      return resolvedResults;
    }
  };
  useEffect(() => {
    console.log(
      "loaded... group function",
      applicantData?.expatriate_work_permit_documents
    );
    groupDocument(applicantData?.expatriate_work_permit_documents);
  }, [applicantData]);

  useEffect(() => {
    console.log('====================================');
    console.log(openAccordion);
    console.log('====================================');
  },[openAccordion, setOpenAccordion])
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (GetLatestProfilePic(applicantData)) {
        const url = await getImageUrl(GetLatestProfilePic(applicantData));
        setImageUrl(url);
      }
    };

    fetchImageUrl();
  }, [GetLatestProfilePic(applicantData)]);
  return (
    <>
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.0.0/datepicker.min.js"></script>
      </Helmet>
      <div className="flex flex-row flex-wrap md:flex-nowrap gap-4">
        <div className="w-full md:w-2/3 space-y-6">
          {/* tabs start */}
          <div className="text-sm font-medium text-center bg-white text-gray-500 border-b border-gray-200">
            <ul className="flex flex-wrap -mb-px">
              <li onClick={() => setTab("profile")} className="mr-2 p-2">
                <a
                  href="#"
                  className={`${
                    tab === "profile"
                      ? "bg-primary text-white hover:text-gray-200 hover:border-gray-300"
                      : "text-black"
                  } inline-block rounded-3xl px-6 py-2 border border-transparent`}
                >
                  Application Detail
                </a>
              </li>
              <li onClick={() => setTab("expat")} className="mr-2 p-2 ">
                <a
                  href="#"
                  className={`${
                    tab === "expat"
                      ? "bg-primary text-white hover:text-gray-200 hover:border-gray-300"
                      : "text-black"
                  } inline-block rounded-3xl px-6 py-2 border border-transparent`}
                  aria-current="page"
                >
                  Expatriate Profile
                </a>
              </li>
              {/* <li onClick={() => setTab("upload")} className="mr-2 p-2 ">
                <a
                  href="#"
                  className={`${
                    tab === "upload"
                      ? "bg-primary text-white hover:text-gray-200 hover:border-gray-300"
                      : "text-black"
                  } inline-block rounded-3xl px-6 py-2 border border-transparent`}
                  aria-current="page"
                >
                  File Upload
                </a>
              </li> */}
            </ul>
          </div>

          {/* <div class="bg-white rounded-lg shadow-lg p-6">
            <div class="flex items-center justify-center">
              <div class="w-16 h-16 border-4 border-gray-200 rounded-full animate-spin"></div>
            </div>
            <div class="mt-6">
              <div class="font-bold text-lg text-gray-800">
                File Verification Progress
              </div>
              <div class="mt-2 text-gray-600">
                Stages: Uploaded, Finance, Document Checker, EMR
              </div>
              <div class="mt-4 flex items-center">
                <div class="w-3/4 bg-gray-200 rounded-full">
                  <div class="h-2 bg-blue-500 rounded-full"></div>
                </div>
                <div class="w-1/4 ml-2 text-right text-sm text-gray-600">
                  75%
                </div>
              </div>
            </div>
          </div> */}
          {/* end tabs */}

          {/* <div className="w-full flex items-center justify-between gap-4 bg-white rounded-md bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-85 border border-primary p-5">
            <div className="">
              <h3>Work Permit Status</h3>
              <div className="flex gap-4">
                <div className="flex justify-center items-center w-[150px] h-[150px] rounded-full border-[12px] border-primary">
                  <Tooltip tooltipsText="total document uploaded">
                    <h2 className="text-2xl">
                      {applicantData?.expatriate_work_permit_documents?.length}
                    </h2>
                  </Tooltip>
                </div>

                <div className="flex flex-col gap-3 justify-end">
                  <div className="flex flex-col">
                    <span className="text-gray-500 text-[12px]">Permit Status</span>
                    <span className="px-4 py-1 rounded-md bg-yellow40 text-white select-none">Pending</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-500 text-[12px]">EMR Number</span>
                    <span className="px-4 py-1 rounded-md border border-green20 text-black select-none">EMR432433</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <ol className="relative border-s border-gray-200 dark:border-gray-700">
                <li className="mb-5 ms-4">
                  <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                  <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    February 2022
                  </time>
                  <h3 className="text-lg font-semibold text-gray-900 ">
                    Document Review
                  </h3>
                </li>
                <li className="mb-5 ms-4">
                  <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                  <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    March 2022
                  </time>
                  <h3 className="text-lg font-semibold text-gray-900 ">
                    Finance Check
                  </h3>
                </li>
                <li className="ms-4">
                  <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                  <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    April 2022
                  </time>
                  <h3 className="text-lg font-semibold text-gray-900 ">
                    Finalize your Work permit
                  </h3>
                </li>
              </ol>
            </div>
          </div> */}

          {tab === "profile" && (
            <>
              <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
                <button
                  onClick={() => router(-1)}
                  className="bg-gray px-3 py-2 border-2 rounded-md flex gap-2 items-center"
                >
                  <MdArrowBack /> Back
                </button>
                <h2 className="text-primary capitalize font-semibold">
                  Work Permit Details
                </h2>

                {/* warining card */}
                {applicantData?.d && (
                  <PermitWarningCard
                    handleRoute={() =>
                      router(
                        `/organization/requested-work-permit/detail/${applicantData?.id}/upload-file`
                      )
                    }
                  />
                )}
                {/* end card */}

                <div className="flex flex-wrap gap-4 items-start">
                  <div className="flex flex-col gap-4 w-full md:w-2/5">
                    {applicantData?.emr_number && (
                      <div className="rounded bg-N99/30 p-3 space-y-1">
                        <p className="text-sm text-N70 capitalize">
                          EMR number
                        </p>
                        <h3 className="2xl:text-lg text-primary font-semibold tracking-wider">
                          {applicantData?.emr_number || "---"}
                        </h3>
                      </div>
                    )}
                    {applicantData?.tracking_number && (
                      <div className="rounded bg-N99/30 p-3 space-y-1">
                        <p className="text-sm text-N70 capitalize">
                          tracking Number
                        </p>
                        <h3 className="text-lg text-primary font-semibold tracking-wider">
                          {applicantData?.tracking_number || "---"}
                        </h3>
                      </div>
                    )}
                  </div>
                  <div className="md:w-3/5 w-full rounded-md text-sm bg-N99/30 p-3 space-y-4">
                    <div className="flex 2xl:w-11/12 mx-auto items-center gap-4 justify-between">
                      <h3 className="font-medium">Work Expat. Entry Date</h3>
                      <p className="font-semibold">---</p>
                    </div>
                    <div className="flex 2xl:w-11/12 mx-auto items-center gap-4 justify-between">
                      <div className="flex items-center gap-2 text-N50">
                        <BsCalendarDate />
                        <p>Employment Start Date</p>
                      </div>
                      <p className="font-semibold text-xs md:text-sm">
                        {applicantData && applicantData?.start_date}
                      </p>
                    </div>
                    <div className="flex 2xl:w-11/12 mx-auto items-center gap-4 justify-between">
                      <div className="flex items-center gap-2 text-N50">
                        <BsCalendarDate className="text-primary" />
                        <p>Employment Expired Date</p>
                      </div>
                      <p className="font-semibold text-xs md:text-sm">
                        {applicantData && applicantData?.end_date}
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" space-y-2 text-sm">
                  <div className="flex xl:w-1/2 items-center gap-4 justify-between">
                    <h3 className="text-N50">Salary</h3>
                    <p className="font-medium text-green50">
                      {(applicantData && applicantData?.salary) || "0"} ETB
                    </p>
                  </div>
                  <div className="flex xl:w-1/2 items-center gap-4 justify-between">
                    <h3 className="text-N50">Allowance / Tip</h3>
                    <p className="font-medium text-N50">
                      {(applicantData && applicantData?.allowance) || "0"} ETB
                    </p>
                  </div>
                  <div className="flex xl:w-1/2 items-center gap-4 justify-between">
                    <h3 className="text-N50">Intended Position</h3>
                    <p className="font-medium text-N30">
                      {(applicantData &&
                        applicantData?.profession_title?.name_json?.en) ||
                        "---"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
                <div className="flex gap-8">
                  <div className="w-full md:w-1/2 space-y-3 flex flex-col justify-between">
                    <h2 className="text-primary capitalize font-medium">
                      Passport details
                    </h2>
                    <div className="flex items-center gap-4 text-sm justify-between pb-12 border-b">
                      <h3 className="text-N50">Passport number</h3>
                      <p className="font-semibold text-N20">
                        {applicantData?.expatriate?.passport_number || "---"}
                      </p>
                    </div>
                    <div className="flex text-sm items-center gap-4 justify-start md:justify-between">
                      <div className="flex items-center gap-2 text-N50">
                        <BsCalendarDate />
                        <p>Passport Issue Date</p>
                      </div>
                      <p className="font-semibold">
                        {(applicantData && applicantData?.passport_issued_date) || "---"}
                      </p>
                    </div>
                    <div className="flex text-sm items-center gap-4 justify-start sm:justify-between">
                      <div className="flex items-center gap-2 text-N50">
                        <BsCalendarDate className="text-primary" />
                        <p>Passport Expire Date</p>
                      </div>
                      <p className="font-semibold">
                        {(applicantData && applicantData?.passport_expiry_date) || "---"}
                      </p>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 space-y-3">
                    <h2 className="text-primary capitalize font-medium">
                      VISA details
                    </h2>
                    <div className=" space-y-2">
                      <div className="flex items-center gap-4 text-sm justify-between">
                        <h3 className="text-N50">VISA number</h3>
                        <p className="font-semibold text-N20">
                          {applicantData?.expatriate?.visa_number || "---"}
                        </p>
                      </div>
                      <div className="flex items-center gap-4 text-sm justify-between pb-6 border-b">
                        <h3 className="text-N50">VISA Type</h3>
                        <p className="font-semibold text-N20">
                          {applicantData?.expatriate?.visa_type?.name || "---"}
                        </p>
                      </div>
                    </div>
                    <div className="flex text-sm mx-auto items-center gap-4 justify-between">
                      <div className="flex items-center gap-2 text-N50">
                        <BsCalendar2Date />
                        <p>VISA Issue Date</p>
                      </div>
                      <p className="font-semibold">
                        {(applicantData?.expatriate &&
                          applicantData?.visa_issued_date) ||
                          "---"}
                      </p>
                    </div>
                    <div className="flex text-sm mx-auto items-center gap-4 justify-between">
                      <div className="flex items-center gap-2 text-N50">
                        <BsCalendarDate className="text-primary" />
                        <p>VISA Expire Date</p>
                      </div>
                      <p className="font-semibold">
                        {(applicantData && applicantData?.visa_expiry_date) ||
                          "---"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-sm p-4 rounded-md space-y-8">
                <h2 className="text-primary capitalize font-medium">
                  organization information
                </h2>
                <div className="flex flex-wrap md:flex-nowrap items-center gap-4 divide-x-2">
                  <div className="flex flex-col w-full gap-3 md:justify-center justify-start items-center px-4">
                    <div className="p-4 rounded-full bg-N99/60 w-20 h-20 flex items-center justify-center">
                      <BsBuilding className="text-primary text-3xl" />
                    </div>
                    <div className=" space-y-1">
                      <h3 className="font-semibold text-center text-N20">
                        {applicantData?.organization &&
                          applicantData?.organization?.name}
                      </h3>
                      <p className="text-N50 text-sm text-center">
                        {applicantData?.organization &&
                          applicantData?.organization?.sector?.name_json?.en}
                      </p>
                    </div>
                  </div>
                  <div className=" space-y-2 px-6 w-full md:w-4/5">
                    <InfoCard
                      title="email"
                      email={
                        applicantData?.organization &&
                        applicantData?.organization?.email
                      }
                    >
                      <MdEmail />
                    </InfoCard>
                    <InfoCard
                      title="phone number"
                      email={
                        applicantData?.organization &&
                        applicantData?.organization?.phone_no
                      }
                    >
                      <MdPhone />
                    </InfoCard>
                    <InfoCard
                      title="Status"
                      email={
                        applicantData?.organization &&
                        applicantData?.organization?.review_status
                          ? "Approved"
                          : applicantData?.organization?.review_status === false
                          ? "Rejected"
                          : "Pending"
                      }
                    >
                      <MdPhone />
                    </InfoCard>
                    <InfoCard
                      title="tin number"
                      email={
                        applicantData?.organization &&
                        applicantData?.organization?.tin_number
                      }
                    >
                      <MdHouse />
                    </InfoCard>
                    <InfoCard
                      title="business license"
                      email={
                        applicantData?.organization &&
                        applicantData?.organization?.business_licence_number
                      }
                    >
                      <MdFlag />
                    </InfoCard>
                    <InfoCard
                      title="Town"
                      email={
                        applicantData?.organization &&
                        applicantData?.organization?.subcity?.name_json?.en
                      }
                    >
                      <MdEmail />
                    </InfoCard>
                  </div>
                </div>
              </div>
            </>
          )}
          {tab === "expat" && (
            <>
              <div className="w-full bg-white rounded-lg">
                <div className="p-4">
                  <div
                    className="flex items-center p-4 mb-4 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50"
                    role="alert"
                  >
                    <svg
                      className="flex-shrink-0 inline w-4 h-4 mr-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span className="sr-only">Info</span>
                    <div>
                      <span className="font-medium">Info alert!</span> You can
                      update some of the information you provided earlier.
                      Please review and make necessary edits.
                    </div>
                  </div>

                  {disabledForm && (
                    <div
                      className="flex items-center p-4 mb-4 text-sm text-green20 border border-green30 rounded-lg bg-green90"
                      role="alert"
                    >
                      <svg
                        className="flex-shrink-0 inline w-4 h-4 mr-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                      </svg>
                      <span className="sr-only">Info</span>
                      <div>
                        <span className="font-medium">Info alert!</span>
                        You can not update expatriate profile because the permit
                        is already approved
                      </div>
                    </div>
                  )}
                </div>
                <FormProvider onSubmithandler={handleSubmit(handleExpatForm)}>
                  <div className="grid p-4 grid-cols-1 md:grid-cols-2 gap-4">
                    <CInputField
                      label="First Name"
                      name="first_name"
                      disabled={disabledForm}
                      // defaultValue={applicantData.expatriate.first_name}
                      validation={{ required: true }}
                      register={register}
                      errors={errors}
                    />
                    <CInputField
                      label="Father Name"
                      name="father_name"
                      disabled={disabledForm}
                      validation={{ required: true }}
                      register={register}
                      errors={errors}
                    />
                    <CInputField
                      label="Grand Father Name"
                      name="grand_father_name"
                      disabled={disabledForm}
                      validation={{ required: false }}
                      register={register}
                      errors={errors}
                    />
                    <CInputField
                      label="Passport Number"
                      name="passport_number"
                      disabled={disabledForm}
                      validation={{ required: true }}
                      register={register}
                      errors={errors}
                    />
                    <CInputField
                      label="Birth date"
                      type="date"
                      name="date_of_birth"
                      disabled={disabledForm}
                      validation={{ required: true }}
                      register={register}
                      errors={errors}
                    />
                    {/* <CInputField
                      label="Passport Expiry Date"
                      name="passport_expiry_date"
                      type="date"
                      validation={{ required: true }}
                      register={register}
                      errors={errors}
                    />
                    <CInputField
                      label="Passport Issue Date"
                      name="passport_issue_date"
                      type="date"
                      validation={{ required: true }}
                      register={register}
                      errors={errors}
                    /> */}
                    <CInputField
                      label="Contract Start Date"
                      name="start_date"
                      type="date"
                      disabled={disabledForm}
                      validation={{ required: true }}
                      register={register}
                      errors={errors}
                    />
                    <CInputField
                      label="Contract End Date"
                      name="end_date"
                      disabled={disabledForm}
                      validation={{ required: true }}
                      register={register}
                      errors={errors}
                    />
                    {applicantData?.permit_type !== WORK_PERMIT_TYPES.NEW && (
                      // <CInputField
                      //   label="EMR Number"
                      //   name="emr_number"
                      //   validation={{ required: true }}
                      //   register={register}
                      //   errors={errors}
                      // />
                      <div className="flex flex-col">
                        <label>EMR Number</label>
                        <input
                          disabled={disabledForm}
                          type="text"
                          className="w-full rounded-lg active:rounded-lg placeholder:text-sm"
                          {...register("emr_number", {
                            pattern: /^EMR\d+/,
                            required: false,
                            message:
                              "Please enter a valid EMR number start with (EMR) letter followed by a number",
                          })}
                        />
                        {errors.emr_number && (
                          <span>{errors.emr_number.message}</span>
                        )}
                      </div>
                    )}
                    {/* <CSelectField
                      label={"Visa Type"}
                      options={[]}
                      name={"visa_type_id"}
                      register={register}
                      errors={errors}
                    /> */}
                    <CSelectField
                      label={"Nationality"}
                      disabled={disabledForm}
                      options={nationality?.map((item) => {
                        return {
                          ...item,
                          value: item.id,
                        };
                      })}
                      name={"nationality_id"}
                      register={register}
                      errors={errors}
                    />
                  </div>

                  <div className="px-4 py-3">
                    <CButton
                      btnLabel={"Update Information"}
                      type={"submit"}
                      disabled={disabledForm}
                      classes={
                        "bg-primary disabled:opacity-40 disabled:bg-gray-400 w-full"
                      }
                    />
                  </div>
                </FormProvider>

                {/* <div className="mt-8 p-4 relative">
                  <CustomDatepicker />
                </div> */}
              </div>
            </>
          )}
          {tab === "upload" && (
            <div className="bg-white rounded-lg p-4">
              <div className="flex flex-wrap md:flex-nowrap gap-6">
                <div className="w-full md:w-2/3 space-y-6">
                  <div className="bg-white shadow-sm p-4 rounded-md space-y-5">
                    <h2 className="md:ml-8 text-primary capitalize font-semibold">
                      Document Upload
                    </h2>

                    <div className="bg-blue-100 p-2 rounded mt-5 mx-2 md:mx-8">
                      <span className="font-semibold text-lg">Notice</span>
                      <p className="">
                        Please upload only the files that have been rejected or
                        forgotten and have not been uploaded yet. The remaining
                        part is currently disabled and inaccessible.
                      </p>
                    </div>

                    <FormProvider
                      onSubmithandler={handleSubmitUpload(handleFileSubmit)}
                    >
                      <div className="flex flex-col p-2 md:p-8 gap-3">
                        <CSelectField
                          name="catagory"
                          register={registerUpload}
                          errors={errorsUpload}
                          validation={{
                            required: "Document Catagory is required",
                          }}
                          options={documentCatagory
                            ?.filter(
                              (i) =>
                                i?.code !== "REPLACEMENT" &&
                                i?.code !== "CANCELLATION"
                            )
                            ?.map((reg) => {
                              const _filterDoc =
                                applicantData?.expatriate_work_permit_documents?.filter(
                                  (doc) => doc?.properties?.catagory === reg?.id
                                );
                              const isDocumentRejectedOrVerified =
                                _filterDoc.some(
                                  (doc) =>
                                    // doc?.properties?.catagory === reg?.id &&
                                    doc?.review_status === true ||
                                    doc?.review_status === null
                                );
                              return {
                                ...reg,
                                value: reg?.id,
                                name: reg?.code,
                                // disabled: isDocumentRejectedOrVerified,
                              };
                            })}
                          label="Document Catagory"
                          placeholder="Select Catagroy Type"
                        />
                        <CSelectField
                          name="document_type_id"
                          register={registerUpload}
                          errors={errorsUpload}
                          disabled={!watchCatagory}
                          validation={{ required: "Document Type is required" }}
                          options={documentTypes
                            .filter(
                              (i) =>
                                i?.work_permit_type_id ===
                                  WORK_PERMIT_TYPES.NEW &&
                                i?.documents_type_category_id === watchCatagory
                            )
                            ?.map((reg) => {
                              const isDocumentRejectedOrVerified =
                                applicantData?.expatriate_work_permit_documents?.some(
                                  (doc) =>
                                    doc?.document_type_id === reg?.id &&
                                    (doc?.review_status === true ||
                                      doc?.review_status === null)
                                );
                              return {
                                ...reg,
                                value: reg?.id,
                                name: reg?.name,
                                disabled: isDocumentRejectedOrVerified,
                              };
                            })}
                          label="Document Type"
                          placeholder="Select Document Type"
                        />

                        {((selectedCatagory === (BANK_SLIP_ID )) || (selectedCatagory === ( BANK_SLIP_ID1)) || (selectedCatagory === ( BANK_SLIP_ID2)) || (selectedCatagory === ( BANK_SLIP_ID3))) && (
                          <CInputField
                            label={"Bank Reference Number"}
                            errors={errorsUpload}
                            name={"bank_refernce_number"}
                            register={registerUpload}
                            type="text"
                          />
                        )}

                        {/* <FileInput
                          type={
                            selectedCatagory === PROFILE_IMAGE_ID ||
                            selectedCatagory === BANK_SLIP_ID
                              ? "image"
                              : "file"
                          }
                          onChange={handleFileChange}
                          enablePreview={true}
                        /> */}
                        <CButton
                          classes={"bg-primary"}
                          loading={loading}
                          btnLabel={"Submit Document"}
                          type={"submit"}
                        />
                      </div>
                    </FormProvider>
                  </div>
                </div>
                <div className="w-full md:w-1/3">
                  <div className="bg-white p-3">
                    <h4 className="font-semibold">Uploaded Documents</h4>
                    <div className="flex flex-col gap-2">
                      {permitDocument &&
                        permitDocument.map((item) => (
                          <WorkPermitCard
                            title={item?.title}
                            path={item?.files}
                            note={item?.review_note}
                            status={
                              item?.review_status
                                ? "Verified"
                                : item?.review_status === false
                                ? "Rejected"
                                : "Pending"
                            }
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-10 md:mt-0 w-full md:w-1/3 space-y-6">
          <div className="bg-white shadow-sm p-4 rounded-md">
            <div className="flex flex-col gap-3 items-center -translate-y-1/3">
              <img
                src={
                  applicantData?.expatriate_work_permit_documents && imageUrl
                }
                className="rounded-full bg-N99/60 w-20 2xl:w-24 h-20 2xl:h-24 flex items-center justify-center object-cover"
              />

              <div className=" space-y-1">
                <h3 className="font-semibold text-N20">
                  {applicantData?.expatriate?.first_name +
                    " " +
                    applicantData?.expatriate?.father_name}
                </h3>
                <p className="text-N50 text-sm text-center">
                  {applicantData &&
                    applicantData?.expatriate_work_permit_type?.name + " "}
                  Application
                </p>
              </div>
            </div>
            <div className=" space-y-2 -mt-6 pb-8 border-b border-b-N95 mb-6">
              <InfoCard title="email" email={applicantData?.expatriate?.email}>
                <MdEmail />
              </InfoCard>
              <InfoCard
                title="phone number"
                email={applicantData?.expatriate?.phone_number}
              >
                <MdPhone />
              </InfoCard>
              <InfoCard
                title="gender"
                email={applicantData?.expatriate?.gender?.name}
              >
                <MdPhone />
              </InfoCard>
              <InfoCard
                title="date of birth"
                email={applicantData?.expatriate?.date_of_birth}
              >
                <MdHouse />
              </InfoCard>
              <InfoCard
                title="maritial status"
                email={applicantData?.expatriate?.marital?.name}
              >
                <MdFlag />
              </InfoCard>
              <InfoCard
                title="passport number"
                email={applicantData?.expatriate?.passport_number}
              >
                <MdEmail />
              </InfoCard>
            </div>
            {currentRole !== roles.WP_ORGANIZATION && (
              <>
                <div className="flex text-sm mx-auto items-center gap-4 justify-between mb-3">
                  <div className="flex items-center gap-2 font-medium text-N0">
                    <BsUpcScan />
                    <p>Financial Verification</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsFillPersonFill className="text-green50 text-lg" />
                    <FiAlertCircle className="text-N80 text-lg" />
                  </div>
                </div>
                <div className="flex text-sm mx-auto items-center gap-4 justify-between mb-3">
                  <div className="flex items-center gap-2 font-medium text-N0">
                    <BsUpcScan />
                    <p>Document Verification</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsFillPersonCheckFill className="text-green50 text-lg" />
                    <FiAlertCircle className="text-N80 text-lg" />
                  </div>
                </div>
              </>
            )}

            <div className="flex text-sm mx-auto items-center gap-4 justify-between">
              <div className="flex items-center gap-2 font-medium text-N0">
                <BsFillPersonFill />
                <p>{"New Application"}</p>
              </div>
              <p>-</p>
            </div>
          </div>
          {!applicantData?.review_status && (
            <div className="bg-white p-4">
              <p>
                Please utilize this button to upload unfinished or rejected
                documents.
              </p>
              <button
                type="button"
                onClick={() =>
                  router(
                    `/organization/requested-work-permit/detail/${applicantData?.id}/upload-file`
                  )
                }
                className="border-2 border-gray-50 rounded-md px-4 py-2 bg-primary text-white mb-4"
              >
                Upload Document
              </button>
            </div>
          )}
          <div className="bg-white shadow-sm p-4 rounded-md space-y-4">
            <StatusCard
              status={
                applicantData?.review_status === true
                  ? "verified"
                  : applicantData?.review_status === false
                  ? "rejected"
                  : "pending"
              }
            />
          </div>
          <div className="bg-white shadow-sm p-4 rounded-md space-y-4">
            <h2 className="text-primary capitalize font-medium text-sm">
              work permit documents
            </h2>

            {groupedData?.map((doc) => (
              <div>
                <div id="accordion-collapse" data-accordion="collapse">
                  <h2 id="accordion-collapse-heading-1">
                    <button
                      onClick={() =>
                        setOpenAccordion({
                          id: doc?.documentType,
                          status: !openAccordion.status,
                        })
                      }
                      type="button"
                      class="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-gray-200 rounded-md focus:ring-4 focus:ring-gray-200 hover:bg-gray-100 gap-3"
                    >
                      <p className="flex flex-col justify-start items-start text-start">
                        <span>{applicantData.expatriate_work_permit_type.name ===
                              "Renewal" &&  doc?.documentType === "Visa" ? 'Visa' : doc?.documentType }</span>
                        <small
                          className={`${
                            doc.overallStatus === true
                              ? "bg-green/25"
                              : doc.overallStatus === false
                              ? "bg-red/25"
                              : "bg-yellow/25"
                          } rounded-lg px-2 `}
                        >
                          {doc.overallStatus === true
                            ? "verified"
                            : doc.overallStatus === false
                            ? "Rejected"
                            : "pending"}
                        </small>
                      </p>
                      <svg
                        data-accordion-icon
                        class="w-3 h-3 rotate-180 shrink-0"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5 5 1 1 5"
                        />
                      </svg>
                    </button>
                  </h2>
                  {openAccordion?.id === doc?.documentType &&
                    openAccordion?.status === true && (
                      <div
                        id="accordion-collapse-body-1"
                        class=""
                        aria-labelledby="accordion-collapse-heading-1"
                      >
                        <div class="p-5 border border-b-0 border-gray-200">
                          <WorkPermitCard
                            key={doc.documentType}
                            title={
                              applicantData.expatriate_work_permit_type.name ===
                              "Renewal"
                                ? doc?.documents[doc?.documents?.length - 1]?.title === "Visa"
                                  ? "Visa"
                                  : doc.documents[doc?.documents?.length - 1]?.title
                                : doc.documents[doc?.documents?.length - 1]?.title
                            }
                            path={doc.documents[doc?.documents?.length - 1]?.files}
                            note={doc.documents[doc?.documents?.length - 1]?.review_note}
                            status={
                              doc.documents[doc?.documents?.length - 1]?.review_status === null
                                ? "Pending"
                                : doc.documents[doc?.documents?.length - 1]?.review_status === false
                                ? "Rejected"
                                : "Verified"
                            }
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            ))}

            {/**
             * 
             {applicantData?.expatriate_work_permit_documents?.length > 0 &&
               applicantData?.expatriate_work_permit_documents?.map(
                 (item, index) => (
                   <WorkPermitCard
                     key={index}
                     title={
                       applicantData.expatriate_work_permit_type.name ===
                       "Renewal"
                         ? item?.title === "Visa"
                           ? " Immigration ID and Work permit ID"
                           : item?.title
                         : item?.title
                     }
                     path={item?.files}
                     note={item?.review_note}
                     status={
                       item?.review_status === null
                         ? "Pending"
                         : item?.review_status === false
                         ? "Rejected"
                         : "Verified"
                     }
                   />
                 )
               )}
             
             */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailOrganization;
